/* Sidebar
-------------------------------------------------- */

.sidebar {
  position: fixed;
  bottom: 0;
  z-index: $stack-order-sidebar;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $grid-outer-margin $grid-outer-margin;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }

  @include breakpoint(md) {
    display: block;
    right: $grid-outer-margin;
    left: auto;
    padding: 0;
  }

  &-scroll-to-top--is-visible {
    .sidebar-button--scroll-to-top {
      transform: translateY(0);

      @include breakpoint(md) {
        bottom: $grid-outer-margin;
      }
    }

    .sidebar-contact-widget {
      @include breakpoint(md) {
        bottom: calc(#{$grid-outer-margin} * 3 + 20px);
      }
    }
  }

  &-chat--is-visible {
    .sidebar-button--scroll-to-top,
    .sidebar-contact-widget {
      display: none;
    }

    .smoope-embedded-chat-wrapper {
      display: block !important;
    }
  }

  /* Lang Switch
-------------------------------------------------- */
  &__language-list {
    select {
      appearance: none;
      font-weight: bold;
      padding: 0;

      &:focus {
        border: 0;
        outline: 0;
        color: #0A0D0F;
      }

      color: #FFF;

      option:not(:checked) { color: black; } /* or whatever your default style is */
    }
  }

  /* Contact Widget
-------------------------------------------------- */

  &-contact-widget {
    transition: bottom ease-in-out 0.3s;
    border-radius: 0.25em;
    overflow: hidden;
    display: flex;
    box-shadow: $ui-element-box-shadow;

    @include breakpoint(md) {
      position: absolute;
      display: block;
      right: 0;
      bottom: $grid-outer-margin * 2;
    }

    &__button {
      background: $color-bob-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: none;
      color: #FFF;
      outline: 0;
      padding: 0 10px;
      height: $grid-outer-margin * 2;
      width: $grid-outer-margin * 2;
      cursor: pointer;

      &:not(:focus):hover {
        background: $color-bob-yellow;
        color: white;
      }

      .icon {
        width: 1.75rem;
        height: 1.75rem;
        pointer-events: none;
      }

      &--login {
        background: $color-bob-yellow;
        color: white;

        &:not(:focus):hover {
          background: $color-bob-blue;
          color: white;
        }
      }
    }
  }

  /* Scroll to Top
  -------------------------------------------------- */

  &-button--scroll-to-top {
    display: flex;
    width: $grid-outer-margin * 2;
    height: $grid-outer-margin * 2;
    color: #FFF;
    text-decoration: none !important;
    background: $color-bob-blue;
    border-radius: 0.25em;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    -webkit-touch-callout: none;
    border-bottom: none;
    outline: 0;
    box-shadow: $ui-element-box-shadow;
    transform: translateY(200%);
    transition: bottom ease-in-out 0.3s, transform ease-in-out 0.3s;

    @include breakpoint(md) {
      transform: translateY(0);
      position: absolute;
      right: 0;
      bottom: - $grid-outer-margin * 2;
    }

    &:hover {
      background: $color-bob-yellow;
      color: #FFF;
    }
  }

  .smoope-embedded-chat-wrapper {
    display: none;
  }
}
