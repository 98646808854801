.tooltip-source {
  display: inline-block;
  color: $color-bob-blue-30;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    opacity: 0.7;
    transform: scale(1.2);
    outline: 0;
  }

  > * {
    pointer-events: none;
  }
}

// if the overlay is in between the <main>-tags, this could be moved into `.yt-main` wrapper
.tooltip-backdrop {
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: $stack-order-tooltip;

  &.tooltip--hidden {
    display: none;
  }
}

.tooltip {
  @include box-shadow(0 2px 15px 3px, rgba(0,0,0,0.14));

  position: absolute;
  width: 100vw;
  max-width: 100%;
  background: #FFF;
  opacity: 1;
  display: flex;
  color: inherit;
  border-radius: 0.5625rem;

  @media (max-width : $screen-md - 1) {
    max-height: calc(100vh - 90px) !important;
    left: 0 !important;
    top: 20px !important;
  }

  @include breakpoint(md) {
    width: 500px;
  }

  &__btn-close {
    cursor: pointer;
    background: none;
    border: 0;
    // color: inherit;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    padding: 10px;
    z-index: 2;

    > * {
      pointer-events: none;
    }
  }

  &__content {
    max-height: inherit !important;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    text-align: left;
    padding: 25px;

    @include breakpoint(md) {
      padding: 30px;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__pointer {
    position: absolute;
    width: 70px;
    height: 40px;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    display: none;

    @include breakpoint(md) {
      display: block;
    }

    &::after {
      content: "";
      position: absolute;
      width: 60px;
      height: 60px;
      background: #FFF;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      top: 60px;
      left: 50%;

      @include box-shadow(0 2px 15px 3px, rgba(0, 0, 0, 0.14));
    }

    &.tooltip__pointer--left {
      left: 40px; //25px;
    }

    &.tooltip__pointer--bottom {
      top: 100%;

      &::after {
        top: -18px;
      }
    }

    &.tooltip__pointer--right {
      right: -30px; //-45px;
      left: auto;
    }
  }
}
