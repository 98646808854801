/* Faq Filter with Accordions per FAQ-Category
-------------------------------------------------- */

.partner-downloads-accordions {
  &__filter {
    padding-top: 1rem;

    li {
      display: inline-block;
      margin: 0 1rem 1rem 0;
    }
  }
}
