/* Calculator
-------------------------------------------------- */

.calculator {
  hr {
    @include breakpoint(md) {
      display: none;
    }
  }

  &-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &-col--shrink {
    flex-shrink: 1;
    flex-basis: 50%;
  }

  &-col--grow {
    flex-grow: 1;
    flex-basis: 50%;
  }

  &-form {
    background: #FFF;
    padding: $content-box-padding;
    border-radius: $default-border-radius;
    box-shadow: $default-box-shadow;
    color: $text-color;
    text-align: left;

    @include breakpoint(md) {
      padding: $content-box-padding * 2;
    }

    .control-group--horizontal .control--radio {
      margin-left: 1rem;
    }

    label .headline {
      text-align: left;

      @include breakpoint(xs) {
        font-weight: normal;
      }
    }

    &__icon--info {
      color: $color-bob-blue;
      cursor: pointer;
    }

    .yourppi__min,
    .yourppi__max {
      margin-bottom: $content-margin-vertical;
    }

    .yourrate__min,
    .yourrate__max {
      color: $color-bob-blue;
      white-space: nowrap;

      @include breakpoint(sm) {
        font-size: 2.5rem;
      }
    }

    &__output {
      background: $color-bob-white;
      border-radius: 0.25rem;
      line-height: 2.75rem;
      padding: 0 1.375rem;
      display: inline-flex;
      white-space: nowrap;
      justify-content: space-between;
      border: 1px solid $color-bob-grey;

      @include breakpoint(xs) {
        font-size: 1.25rem !important;
      }

      @include breakpoint(sm) {
        min-width: 14.375rem;
      }

      &-unit {
        opacity: 0.5;
      }
    }

    footer {
      display: flex;
      flex-direction: column;

      @include breakpoint(md) {
        display: block;
      }

      .calculator-form__submit-button-wrapper {
        order: 1;
        margin-bottom: $content-margin-vertical;

        @include breakpoint(md) {
          margin-bottom: 0;
        }
      }

      p {
        order: 2;
      }
    }
  }

  &-crisis-box {
    background-color: $color-bob-blue-30;
    margin: 1em 0 0;
    padding: 1em 1.5em;
    border-radius: 0.25em;
    color: #FFF;
  }
}

.hero-content + .calculator {
  background-color: transparent;
  padding-bottom: $content-margin-vertical;
  padding-top: 0;

  @include breakpoint(lg) {
    margin-top: -4rem;
  }

  .calculator-form {
    @media (max-width : $screen-lg - 1px) {
      padding-right: 0;
      padding-left: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }
}
