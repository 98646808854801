/* Intro
-------------------------------------------------- */

.intro {
  &.module-background--white {
    padding: 0;
  }

  .row {
    min-height: 180px;
  }

  .headline--1 {
    margin-bottom: 0;
  }

  &__text {
    margin-top: $content-margin-vertical;
  }
}
