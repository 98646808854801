/* Typography
-------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: 1.25;
  font-weight: 400;
  margin: 0 0 0.5rem;

  // @see `JavaScript hyphenation polyfill` section in our main JavaScript file
  // word-break: break-word;
  // hyphens: auto;

  a {
    text-decoration: none;
  }

  a:hover {
    color: inherit;
  }

  &::after {
    content: " .";
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    display: inline-block;
  }
}

p,
ul,
ol,
pre {
  font-size: inherit;
  line-height: inherit;
  margin: 0 0 $content-margin-vertical;
}

// Reset the margin-bottom of all the latest elements
main {
  p:last-child,
  ul:last-child,
  ol:last-child,
  pre:last-child {
    margin-bottom: 0;
  }
}

b,
strong {
  font-weight: 700;
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

nav a,
a[href^="tel:"],
a[href^="fax:"] {
  text-decoration: none;
}

hr {
  margin-top: $content-margin-vertical;
  margin-bottom: $content-margin-vertical;
  color: currentColor;
  border-top: 2px solid;
  opacity: 0.25;

  &.hr--light {
    color: $color-bob-blue-90;
    opacity: 1;
  }
}

/* Typography Helper
-------------------------------------------------- */

body {
  // Helper classes are weighted more heavily by the body element

  .text {
    &--align-center {
      text-align: center;
    }

    &--align-left {
      text-align: left;
    }

    &--align-right {
      text-align: right;
    }

    &--size-tiny {
      font-size: $font-size-tiny;
    }

    &--size-large {
      font-size: $font-size-large;
      font-weight: 700;
    }

    &--highlighted {
      color: $primary-color;
    }

    &--color-weaken {
      opacity: 0.5;
    }

    &--richtext {
      // Unordered list
      ul {
        li {
          position: relative;
          margin: ($content-margin-vertical / 2) 0 0;
          padding: 0 0 0 1.75rem;

          &::before {
            content: "• ";
            position: absolute;
            top: 0;
            left: 0;
            font-size: 1.5em;
            font-weight: 700;
            line-height: 0.9;
            color: $primary-color;
          }

          li {
            margin-left: $content-margin-vertical;
          }
        }
      }

      // Ordered list
      ol {
        li {
          margin: ($content-margin-vertical / 2) 0 0;
          padding: 0;
          list-style: decimal inside none;

          li {
            margin-left: $content-margin-vertical;
            list-style: lower-latin inside none;
          }
        }
      }
    }
  }
}

.module-background--dark-blue .text {
  &--richtext {
    // Unordered list
    ul {
      li {
        &::before {
          color: #FFF;
        }
      }
    }
  }
}
