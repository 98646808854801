/* Form & Input Fields
-------------------------------------------------- */

::placeholder {
  color: $input-placeholder-color;
  font-size: 1.25rem;
  line-height: 1.2;
}

fieldset {
  margin: 0 0 ($content-margin-vertical * 2);
}

.input {
  position: relative;
  text-align: left;
  margin: 0 0 ($content-margin-vertical * 2);

  label {
    cursor: pointer;
  }

  &--text,
  &--textarea {
    label {
      position: absolute;
      top: 0.4rem;
      left: calc(1.25rem + 2px); // - border-width
      font-size: 0.75rem;
      color: $input-placeholder-color;
      pointer-events: none;
      z-index: 1;
    }
  }

  textarea {
    min-height: 8em;
    resize: vertical;
  }

  textarea,
  input[type="text"],
  input[type="email"],
  input[type="search"] {
    background-color: $color-bob-white;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    border: 1px solid $color-bob-grey !important;
    border-radius: 0.25em !important;
    margin: 0;
    padding: 0.45rem 0.75rem !important;
    font-family: "Foundry", "Arial", sans-serif;
    font-weight: 500;
    font-size: 1em;
    color: $color-bob-black;
    vertical-align: middle;
    outline: 0;

    &:hover,
    &:focus {
      border-color: $primary-color;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="search"] {
    padding: 0 1.25rem;
    line-height: 3rem;
    height: 3rem;
    white-space: nowrap;
  }

  label + textarea,
  label + input[type="text"],
  label + input[type="email"],
  label + input[type="search"] {
    padding-top: 1rem;
    padding-bottom: 0;
  }

  &--add-on {
    input[type="text"],
    input[type="email"],
    input[type="search"] {
      padding-right: 6em;
    }
  }

  &__add-on {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    .button {
      min-width: 0;
    }
  }
}

.module-background--yellow .input {
  textarea,
  input[type="text"],
  input[type="email"],
  input[type="search"] {
    background-color: #FFF;
  }
}

// Radio tab
.radio-tab {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 $content-margin-vertical;
  $radio-tab-border-radius: 0.5rem;

  &__content {
    background-color: $color-bob-blue-90;
    display: none;
    flex-direction: column;
    padding: 1rem;
    border-radius: $radio-tab-border-radius;
    order: 1;
    flex: 1 0 100%;
    vertical-align: baseline;

    div {
      display: flex;
      justify-content: space-between;
    }
  }

  &__label {
    padding: 0.5rem 0;
  }

  &__tab {
    position: relative;
    overflow: visible;
    margin: 0 0 0 auto;
    padding: 0.5rem 1rem;
    border-radius: $radio-tab-border-radius $radio-tab-border-radius 0 0;
    cursor: pointer;

    &:last-of-type {
      margin: 0 1rem 0 0;
    }
  }

  input[type="radio"]:checked + &__tab {
    background-color: $color-bob-blue-90;
  }

  input[type="radio"]:checked + &__tab + &__content {
    display: flex;
  }
}

// Filter tag
.filter-tag {
  input {
    visibility: hidden;
    position: absolute;
  }
}
