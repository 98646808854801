
/* Calculator Container */

/**
 * Breakpoints
 * $screen-xs: 600px !default;
 * $screen-sm: ($screen-xs + 1) !default;
 * $screen-md: 940px !default;
 * $screen-lg: 1200px !default;
 *
 * Breakpoint mixin
 *
 * $screen-xs wide and below
 * $screen-sm wide and up
 * $screen-md wide and up
 * $screen-lg wide and up
 *
 * Usage:
 * @include breakpoint(xs) { … }
 */

#calculatorContainer {
  width: 100%;
  max-width: 100%;
  color: $color-bob-black;
  background: $color-bob-white;
  padding: 1.5rem;
  border-radius: 0.25rem;

  @include breakpoint(lg) {
    margin-top: 2.5rem;
    padding: 2.5rem;
  }

  .right-wrapper {
    h3 {
      padding: 25px 0 0 0;
      font-size: 28px;
      border-top: 2px solid $color-bob-blue-90;
      text-align: left;
      margin: 0 0 10px 0;

      @include breakpoint(lg) {
        border: 0;
        padding: 0 0 0 25px;
      }
    }
  }

  /* output */
  #output {
    padding: 0;

    @include breakpoint(lg) {
      padding: 0 25px 25px 25px;
    }

    font-weight: bold;
    color: $color-bob-blue;
    font-size: 2.5rem;

    &::before {
      color: $color-bob-black;
      opacity: 0.5;
      content: "CHF ";
      font-size: 2rem;
    }
  }

  .hint {
    padding: 0;

    @include breakpoint(lg) {
      padding: 0 25px 25px 25px;
    }

    color: $color-bob-black;
    opacity: 0.5;
    font-size: 12px;

    &.bobLegal {
      margin-top: 15px;

      a {
        color: var(--currency-color);
      }
    }
  }

  /*********** form ***********/
  #calculatorForm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    background: white;
    width: 100%;
    max-width: 100%;
  }

  .flex-item {
    padding: 0;

    &:nth-child(1) {
      flex: 0 1 auto;
      width: 100%;
    }

    &:nth-child(2) {
      flex: 1 1 auto;
      width: 50%;
      min-width: 100%;

      @media (min-width : 930px) {
        min-width: 200px;
      }
    }

    &:nth-child(3) {
      flex: 1 1 auto;
      width: 50%;
      min-width: 200px;
      padding-left: 0;

      @media (min-width : 930px) {
        padding-left: 30px;
      }
    }
  }

  /*********** Radio Button Container ***********/
  .termsContainer {
    position: relative;
    padding: 25px 0;

    @include breakpoint(lg) {
      padding: 25px 0 25px 25px;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    label.termsLabel {
      display: block;
      width: 100%;
      flex: 1 0 100%;

      @include breakpoint(lg) {
        font-weight: bold;
      }
    }
  }

  /*********** Radio ***********/
  .termsContainer input[type="radio"] {
    position: fixed;
    opacity: 0;
    pointer-events: none;
  }

  .termsContainer .button {
    margin: 0.625rem;
    min-width: 0;
  }

  .termsContainer input[type="radio"]:first-of-type + label.button {
    margin-left: 0;
  }

  .termsContainer input[type="radio"]:last-of-type + label.button {
    margin-right: 0;
  }

  .termsContainer input[type="radio"]:checked + label.button {
    background-color: $color-bob-yellow;
    color: $color-bob-black;
    z-index: 1;
  }

  /*********** Slider Container ***********/
  .input--slider {
    position: relative;
    padding: 25px 0 20px 0;

    @include breakpoint(lg) {
      padding: 25px 0;
    }

    label {
      color: $color-bob-black;
      margin-bottom: 25px;
      display: block;
      position: relative;
      font-size: rem(16px);
      text-align: inherit;

      @include breakpoint(sm) {
        margin-bottom: 35px;
      }

      @include breakpoint(lg) {
        font-weight: bold;
      }

      span {
        position: absolute;
        top: 0;
        right: 0;
        border: 1px solid $color-bob-grey;
        color: $color-bob-black;
        border-radius: 0.25rem;
        line-height: 2.75rem;
        padding: 0 1.375rem;

        @media (max-width : 330px) {
          font-size: rem(16px);
          border: 0;
          padding: 0;
          line-height: normal;
          line-height: 1.54;
          position: relative;
          display: block;
          width: 100%;
          text-align: right;
        }

        @include breakpoint(xs) {
          font-size: 1.25rem !important;
          border: 0;
          padding: 0;
          line-height: normal;
        }

        @include breakpoint(sm) {
          min-width: 14.375rem;
        }

        text-align: center;
        font-size: 28px;
        font-weight: 700;

        em {
          font-style: normal;
          color: $color-bob-black;
          opacity: 0.5;
          font-size: 1.25rem;
        }
      }
    }
  }
}
