#CybotCookiebotDialog {
  font-family: $font-family !important;

  a {
    font-family: $font-family !important;
  }

  #CybotCookiebotDialogBody,
  #CybotCookiebotDialogDetail {
    font-family: $font-family !important;
    padding: 0 !important;

    #CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
      font-family: $font-family !important;
      padding: 12px 8px 0 8px !important;
    }
  }

  #CybotCookiebotDialogBodyContentTitle,
  #CybotCookiebotDialogBodyContentText {
    font-family: $font-family !important;
    width: 98% !important;
  }

  #CybotCookiebotDialogBodyLevelButtons {
    margin: 0 !important;

    div {
      padding-top: 0 !important;
    }
  }

  #CybotCookiebotDialogBodyContent {
    padding: 12px !important;
    width: 100% !important;
  }

  #CybotCookiebotDialogBodyLevelWrapper {
    text-align: left;
  }

  /*
    Btn Wrapper
  */
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
    position: relative;
    width: 100% !important;

    /*
      Alle erlauben:
      #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll
      */

    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
      position: relative;
      overflow: hidden;
      display: inline-block;
      border: 2px solid transparent;
      width: 100% !important;
      padding: 0.25rem 0.75rem !important;
      font-family: $font-family !important;
      font-weight: 500 !important;

      /* font-size: 1.25rem !important; */
      line-height: 1.2 !important;
      text-decoration: none;
      text-align: center;
      vertical-align: middle;
      word-wrap: normal;
      user-select: none;
      border-radius: 0.25em !important;
      cursor: pointer;
      -webkit-touch-callout: none;
      appearance: none;
      box-shadow: $ui-element-box-shadow;
      transition: background-color $transition-ui-element, box-shadow $transition-ui-element;
      height: auto !important;
      max-width: 200px !important;
      background-color: $color-bob-yellow !important;
      color: $text-color !important;
      border-color: transparent !important;

      /* margin: 0 !important; */
      min-width: 0 !important;
      font-size: 1rem !important;
      margin: ($content-margin-vertical / 2) !important;

      &:hover,
      &:focus {
        background-color: $color-bob-yellow-hover !important;
      }
    }

    /* Auswahl bestätigen */
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
      background-color: transparent !important;
      border-color: #CCC !important;
      box-shadow: none;

      &:hover,
      &:focus {
        background-color: rgba($color-bob-blue, 0.09) !important;
      }
    }

    margin-bottom: 10px !important;
  }

  #CybotCookiebotDialogBodyLevelButtonsRow {
    position: relative;
    margin: ($content-margin-vertical / 2) #{-($content-margin-vertical / 2)} #{-($content-margin-vertical / 2)};

    #CybotCookiebotDialogBodyLevelButtonsSelectPane {
      margin: ($content-margin-vertical / 2);
      border: 0 !important;
      vertical-align: middle;
    }

    #CybotCookiebotDialogBodyLevelDetailsWrapper {
      border: 0 !important;
      margin: ($content-margin-vertical / 2);
      background: transparent;
    }
  }
}
