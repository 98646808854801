.readmore-text--excerpt,
.readmore-button {
  display: none;
}

.readmore--is-active {
  .readmore-button {
    margin-top: 0.5rem;
    display: block;
    color: $color-bob-blue;
    text-decoration: none;
    cursor: pointer;

    &::before {
      content: attr(data-coll-label);
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .readmore-text--full {
    display: none;
  }

  .readmore-text--excerpt {
    display: block;
  }

  &.readmore--is-expanded {
    .readmore-text--excerpt {
      display: none;
    }

    .readmore-text--full {
      display: block;
    }

    .readmore-button::before {
      content: attr(data-exp-label);
    }
  }
}
