/* Cookie Hint
-------------------------------------------------- */

.cookie-hint {
  background: rgba($color-bob-dark, 0.35);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $stack-order-cookieHint;
  display: none;

  .container,
  .row {
    height: 100%;
  }

  &__box {
    background: #FFF;
    padding: ($grid-outer-margin * 2) $grid-outer-margin;
    box-shadow: $default-box-shadow;
    border-radius: $default-border-radius;
  }

  .headline {
    hyphens: none;
    margin-bottom: 1rem;
  }

  p:last-of-type {
    margin-bottom: ($content-margin-vertical * 1.5);
  }
}

// Display cookie hint
html:not(.type-meta-page) .cookie-hint.is-active {
  display: block;
}
