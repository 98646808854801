// excess height to improve interactive area / accessibility
$height: 2.5rem;
$thumb-height: 1.875rem;
$track-height: 0.625rem; // should be pair

// colours
$upper-color: $color-bob-blue-90;
$lower-color: $color-bob-yellow;
$thumb-color: $color-bob-yellow;
$thumb-hover-color:  $color-bob-yellow-hover;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;
$lower-background-initial: linear-gradient(to bottom, $color-bob-blue-90, $color-bob-blue-90) 100% 50% / 100% $track-height no-repeat transparent;

// Webkit cannot style progress so we fake it with a long shadow
// on the thumb element
@function webkit-slider-thumb-shadow($i: 1) {
  // set vertical-height and spread manually playing around to fix through-shining yellow track
  $val: #{$i}px 0.4px 0 -10px #{$upper-color};

  @if $i == 1 {
    @for $k from 2 through 1000 {
      $val: #{$val}, webkit-slider-thumb-shadow($k);
    }
  }

  @return $val;
}

.input--slider {
  width: 100%; /* Width of the outside container */

  /* --------------------------------------------------
  * ----- Slider (animated version styles) ------
  * -------------------------------------------------- */

  input.input-range--animate-js[type="range"]::-webkit-slider-runnable-track {
    background: $lower-background-initial;
  }

  input.input-range--animate-js[type="range"]::-moz-range-progress {
    background: $lower-background-initial;
  }

  input.input-range--animate-js[type="range"]::-ms-fill-lower {
    background: $lower-background-initial;
  }

  input.input-range--animate-js[type="range"]::-webkit-slider-thumb {
    opacity: 0;
  }

  input.input-range--animate-js[type="range"]::-moz-range-thumb {
    opacity: 0;
  }

  input.input-range--animate-js[type="range"]::-ms-thumb {
    opacity: 0;
  }

  /* --------------------------------------------------
  * ----- Slider ------
  * -------------------------------------------------- */

  input[type="range"] {
    display: block;
    appearance: none;
    width: 100%;
    margin: 0;
    height: $height;
    overflow-x: hidden;
    overflow-y: visible;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  // Webkit
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: $height;
    background: $lower-background;
  }

  input[type="range"]::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $thumb-color;
    border-radius: 100%;
    border: 0;
    top: 50%;
    margin-top: calc(-#{$thumb-height} / 2);
    box-shadow: webkit-slider-thumb-shadow();
    transition: background-color 150ms;
    // transition: all 0.2s ease-out;
  }

  // Firefox
  input[type="range"]::-moz-range-track,
  input[type="range"]::-moz-range-progress {
    width: 100%;
    height: $height;
    background: $upper-background;
  }

  input[type="range"]::-moz-range-progress {
    background: $lower-background;
  }

  input[type="range"]::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: $thumb-height;
    width: $thumb-height;
    background: $thumb-color;
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  // Internet Exploder
  input[type="range"]::-ms-track {
    width: 100%;
    height: $height;
    border: 0;
    // color needed to hide track marks
    color: transparent;
    background: transparent;
  }

  input[type="range"]::-ms-fill-lower {
    background: $lower-background;
  }

  input[type="range"]::-ms-fill-upper {
    background: $upper-background;
  }

  input[type="range"]::-ms-thumb {
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $thumb-color;
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    // IE Edge thinks it can support -webkit prefixes
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  input[type="range"]:hover,
  input[type="range"]:focus {
    &::-webkit-slider-thumb {
      background-color: $thumb-hover-color;
    }

    &::-moz-range-thumb {
      background-color: $thumb-hover-color;
    }

    &::-ms-thumb {
      background-color: $thumb-hover-color;
    }
  }
}
