/* Freeform default Flexbox CSS copied from
  app/vendor/solspace/craft-freeform/packages/styles/src/front-end/formatting-templates/flexbox.scss
*/
#form-wrapper {
  &.loading {
    &::before {
      content: " ";
      display: block;
      border: 16px solid #F3F3F3;
      border-radius: 50%;
      border-top: 16px solid #3498DB;
      width: 80px;
      height: 80px;
      animation: spin 2s linear infinite;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9999;
    }

    form {
      opacity: 0.15;
    }
  }
}

/* loading spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }

  100% { transform: rotate(360deg); }
}

.freeform-pages {
  display: flex;
  padding: 0;
  margin: 0 0 10px;
  font-size: 1.25em;
  list-style: none;
}

.freeform-pages li {
  margin: 0 10px 0 0;
  color: $color-bob-grey;
}

.freeform-pages li::before {
  content: "|";
  padding: 0 0.5em;
}

.freeform-pages li:first-child::before {
  content: "";
  padding: 0;
}

.freeform-pages li b {
  color: $color-bob-black;
}

.freeform-row {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
}

.freeform-row .freeform-column {
  flex: 1 0;
  padding: 10px 0;
  margin: 0 15px;
  box-sizing: border-box;
}

.freeform-row .freeform-column > .freeform-row:first-child {
  margin-top: -10px;
}

.freeform-row .freeform-column .input-group-one-line {
  display: flex;
  flex-wrap: wrap;
}

.freeform-row .freeform-column .input-group-one-line label {
  padding-right: 10px;
}

.freeform-row .freeform-column .freeform-label {
  padding-bottom: 7px;
}

.freeform-row .freeform-column.radios.type-radios .freeform-label {

  /* margin-top: 4px;
  padding: 0 0.5rem 0 0; */
  display: block;
}

.freeform-row .freeform-column .freeform-label.freeform-required::after {
  content: "*";
  margin-left: 5px;
}

.freeform-row .freeform-column .freeform-input {
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.freeform-row .freeform-column .freeform-input.StripeElement {
  padding: 4px 2px;
  border: 1px solid $color-bob-grey;
  height: 30px;
}

.freeform-row .freeform-column .freeform-input-only-label {
  font-weight: 400;
}

.freeform-row .freeform-column .ff-errors,
.freeform-row .freeform-column .freeform-errors {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
  text-align: left;
}

.freeform-row .freeform-column .ff-errors > li,
.freeform-row .freeform-column .freeform-errors > li {
  color: $freeform-error-color;
}

.freeform-row .freeform-column .freeform-instructions {
  margin: 0 0 5px;
  font-size: 0.9rem;

  /* display: inline; */
  color: $color-bob-grey;
}

.freeform-row .freeform-column.freeform-column-content-align-left {
  display: flex;
  justify-content: flex-start;
}

.freeform-row .freeform-column.freeform-column-content-align-left > button:not(:first-of-type) {
  margin-left: 5px;
}

.freeform-row .freeform-column.freeform-column-content-align-center {
  display: flex;
  justify-content: center;
}

.freeform-row .freeform-column.freeform-column-content-align-center > button:not(:first-of-type) {
  margin-left: 5px;
}

.freeform-row .freeform-column.freeform-column-content-align-right {
  display: flex;
  justify-content: flex-end;
}

.freeform-row .freeform-column.freeform-column-content-align-right > button:not(:first-of-type) {
  margin-left: 5px;
}

.freeform-row .freeform-column.freeform-column-content-align-spread {
  display: flex;
  justify-content: space-between;
}

.freeform-row .freeform-column.freeform-column-content-align-spread > button:not(:first-of-type) {
  margin-left: 5px;
}

.ff-form-errors {
  background-color: $color-bob-white;
  width: 100%;
  border: 1px solid $freeform-error-color;
  border-radius: 0.25em;
  padding: 0.9rem 1.25rem;
  font-family: "Foundry", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  font-size: 1em;
  color: $freeform-error-color;
  margin-bottom: 20px;
}

.ff-form-errors > p {
  margin: 0;
}

.freeform-form-has-errors {
  color: $freeform-error-color;
}

.type-file span {
  color: $color-bob-grey;
  font-size: 13px;
}

.type-file.has-error span {
  color: $freeform-error-color;
}

/* select -> */
.type-select,
.type-multiple_select,
.type-dropdown {
  position: relative;

  label {
    font-weight: 700;
    padding-bottom: 7px;
  }

  .select-wrapper {
    position: relative;
    overflow: hidden;

    select {
      border: 1px solid $color-bob-grey;
      background: $color-bob-white;
      font-family: "Foundry", "Arial", sans-serif;
      font-weight: 500;
      font-size: 1em;
      line-height: normal;
      height: 2.5em;
      width: 100%;
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      pointer-events: none;
      transform: translateY(-50%);
    }
  }
}

.type-dropdown.has-error,
.type-dropdown.ff-has-errors {
  select {
    color: $freeform-error-color;
    border: 1px solid $freeform-error-color;
  }

  .icon {
    color: $freeform-error-color;
  }
}

.type-checkbox,
.type-checkbox_group,
.type-radio_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.type-checkbox .control-group .control,
.type-radio_group .control-group .control {
  display: inline-block;
  margin-right: 1rem;
}

.type-checkbox_group .control-group .control {
  display: block;
  margin-right: 1rem;
  line-height: 2;
}

.type-checkbox_group label.freeform-label,
.type-checkbox label.freeform-label,
.type-radio_group label.freeform-label {
  width: 100%;
  flex-basis: 100%;
}

.type-checkbox_group label:not(.freeform-label),
.type-checkbox label:not(.freeform-label),
.type-radio_group label:not(.freeform-label) {
  margin-right: 15px;
  font-weight: 400;
}

.type-checkbox_group label:not(.freeform-label) span,
.type-checkbox label:not(.freeform-label) span,
.type-radio_group label:not(.freeform-label) span {
  display: block;
  margin-top: 4px;
  padding: 0 0.5rem;
}

.type-checkbox label.freeform-required span::after {
  content: "*";
  top: 2px;
  right: -10px;
  margin-left: 4px;
}

.type-checkbox_group label.freeform-required::after {
  content: "*";
  top: 2px;
  right: -10px;
}

.type-checkbox .control__indicator,
.type-checkbox_group .control__indicator,
.type-radio_group .control__indicator {
  border: 1px solid $color-bob-grey;
  border-radius: 0.25em;
  background: $color-bob-white;
  width: 2rem;
  height: 2rem;
}

.type-checkbox .control__indicator:hover,
.type-checkbox_group .control__indicator:hover,
.type-radio_group .control__indicator:hover {
  border-color: $color-bob-blue;
}

.type-checkbox .control input:checked ~ .control__indicator,
.type-checkbox .control input:focus ~ .control__indicator,
.type-checkbox_group .control input:checked ~ .control__indicator,
.type-checkbox_group .control input:focus ~ .control__indicator,
.type-radio_group .control input:checked ~ .control__indicator,
.type-radio_group .control input:focus ~ .control__indicator {
  background: $color-bob-white;
}

input[type="email"],
input[type="file"],
input[type="number"],
input[type="tel"],
input[type="password"],
input[type="search"],
input[type="text"],
input[type="url"],
textarea {
  background-color: $color-bob-white;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  border: 1px solid $color-bob-grey;
  border-radius: 0.25em;
  margin: 0;
  padding: 0.45rem 0.75rem;
  font-family: "Foundry", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1em;
  color: $color-bob-black;
  vertical-align: middle;
  outline: 0;
}

input[type="file"]::file-selector-button {
  font-family: "Foundry", "Arial", sans-serif;
  border-radius: 0.25em;
  border: 0;
  padding: 0.45rem 0.75rem;

  /* background: $color-bob-blue-90;
  color: $color-bob-grey; */
  background: $color-bob-grey;
  color: $color-bob-white;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

input[type="email"]:focus,
input[type="email"]:hover,
input[type="file"]:focus,
input[type="file"]:hover,
input[type="number"]:focus,
input[type="number"]:hover,
input[type="tel"]:focus,
input[type="tel"]:hover,
input[type="password"]:focus,
input[type="password"]:hover,
input[type="search"]:focus,
input[type="search"]:hover,
input[type="text"]:focus,
input[type="text"]:hover,
input[type="url"]:focus,
input[type="url"]:hover,
textarea:focus,
textarea:hover {
  border-color: $color-bob-blue;
}

.freeform-input.input::-webkit-input-placeholder {
  font-size: 1rem !important;
  line-height: 1.7 !important;
}

.freeform-input.input::-moz-placeholder {
  font-size: 1rem !important;
  line-height: 1.7 !important;
}

.freeform-input.input:-ms-input-placeholder,
.freeform-input.input::-ms-input-placeholder {
  font-size: 1rem !important;
  line-height: 1.7 !important;
}

.freeform-input.input::placeholder {
  font-size: 1rem !important;
  line-height: 1.7 !important;
}

input[type="email"].ff-has-errors,
input[type="file"].ff-has-errors,
input[type="number"].ff-has-errors,
input[type="tel"].ff-has-errors,
input[type="password"].ff-has-errors,
input[type="search"].ff-has-errors,
input[type="text"].ff-has-errors,
input[type="url"].ff-has-errors,
textarea.ff-has-errors {
  border: 1px solid $freeform-error-color;
}

input[type="email"].ff-has-errors + .control__indicator,
input[type="file"].ff-has-errors + .control__indicator,
input[type="number"].ff-has-errors + .control__indicator,
input[type="tel"].ff-has-errors + .control__indicator,
input[type="password"].ff-has-errors + .control__indicator,
input[type="search"].ff-has-errors + .control__indicator,
input[type="text"].ff-has-errors + .control__indicator,
input[type="url"].ff-has-errors + .control__indicator,
textarea.ff-has-errors + .control__indicator {
  border-color: $freeform-error-color !important;
}

textarea.input {
  margin-bottom: 0;
}

/* ul.ff-errors {
  display: none;
} */

.has-error .control__indicator,
.has-error input,
.has-error select,
.has-error textarea {
  border: 1px solid $freeform-error-color;
}

.has-error label {
  color: $freeform-error-color;
}

.ff-has-errors + .control__indicator {
  border-color: $freeform-error-color !important;
}

.button.btn-success {
  color: $color-bob-white;
  background: $color-bob-blue;
  border: 2px solid transparent;
}

.button.btn-success:hover {
  background: lighten($color-bob-blue, 5%);
}

.button.btn-success:last-child {
  color: $color-bob-black;
  border: 2px solid transparent;
  background: $color-bob-yellow;
}

.button.btn-success:last-child:hover {
  background: $color-bob-yellow-hover;
}

button.input {
  color: $color-bob-grey;
  margin-left: 5px;
  padding: 0;
}

button.input:hover {
  color: $color-bob-blue;
}

button.input::before {
  content: "x ";
}

.opinion-scale .opinion-scale-scales > * > label {
  border-color: $color-bob-grey !important;
}

.opinion-scale .opinion-scale-scales > * input ~ label:hover {
  background: $color-bob-yellow !important;
}

.opinion-scale .opinion-scale-scales > * input:checked ~ label {
  background: $color-bob-blue !important;
  color: $color-bob-white !important;
}

.opinion-scale-legends {
  font-size: 0.9rem;
}

.flatpickr-day.today {
  border-color: $color-bob-blue !important;
}

.flatpickr-day.selected {
  background-color: $color-bob-blue !important;
  border-color: transparent !important;
}

.flatpickr-day:hover {
  background-color: $color-bob-yellow !important;
  border-color: transparent !important;
}

.ff-errors {
  width: 100%;

  span {
    color: $freeform-error-color !important;
  }
}

.freeform-instructions {
  font-size: 0.9em;
  font-style: italic;
  display: block;
}

div[data-freeform-controls] {
  // button[type="submit"] {
  //   position: relative;
  //   overflow: hidden;
  //   display: inline-block;
  //   border: 2px solid transparent;
  //   min-width: 250px;
  //   margin: 0;
  //   padding: 0.5em 1em;
  //   font-family: $font-family;
  //   font-weight: 500;
  //   font-size: 1.25rem;
  //   line-height: 1.2 !important;
  //   text-decoration: none;
  //   text-align: center;
  //   vertical-align: middle;
  //   word-wrap: normal;
  //   user-select: none;
  //   border-radius: 0.25em;
  //   cursor: pointer;
  //   -webkit-touch-callout: none;
  //   appearance: none;
  //   box-shadow: $ui-element-box-shadow;
  //   transition: background-color $transition-ui-element, box-shadow $transition-ui-element;
  //   background-color: $color-bob-yellow;
  //   color: $text-color;
  //   border-color: transparent;
  //
  //   &:hover,
  //   &:focus {
  //     background-color: $color-bob-yellow-hover;
  //   }
  // }
}

/* Non Freeform Forms  */

.form-row {
  div {
    margin-top: 15px;
    margin-bottom: 15px;

    label {
      font-weight: 700;
      padding-bottom: 7px;
    }

    &.has-error {
      color: $freeform-error-color;
      font-weight: 700;

      label {
        color: $freeform-error-color;
      }

      input {
        border: 1px solid $freeform-error-color;
        color: $freeform-error-color;
      }
    }
  }

  ul {
    &.has-error {
      li {
        color: $freeform-error-color;
        font-weight: 700;
      }
    }
  }
}

@media (max-width : $screen-md - 1) {
  .freeform-row {
    display: block;

    .freeform-column {
      flex-basis: 100%;
    }
  }
}
