/* Radio Buttons & Checkboxes
-------------------------------------------------- */

$control-indicator-width: 2rem;
$control-indicator-height: 2rem;
$control-radio-dot-width: 0.875rem;
$control-radio-dot-height: 0.875rem;

.control-group {
  display: inline-block;
  text-align: left;
  vertical-align: top;

  .control {
    position: relative;
    display: block;
    margin-bottom: 15px;
    padding-left: 2.5rem;
    cursor: pointer;
  }

  &--horizontal {
    .control {
      display: inline-block;
    }
  }

  // Hide input -> it's just for the functionality
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  // background of CHECKBOX and RADIO-Button (same "look")
  .control__indicator {
    border: 1px solid $color-bob-grey;
    background: $color-bob-white;
    position: absolute;
    top: 0;
    left: 0;
    width: $control-indicator-width;
    height: $control-indicator-height;
  }

  // Hover and focus states
  // .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    background: $color-bob-blue-90;
  }

  // Checked state
  .control input:checked ~ .control__indicator {
    background: $color-bob-white;
  }

  // Disabled state
  .control input:disabled ~ .control__indicator {
    pointer-events: none;
    opacity: 0.6;
    background: #CCC;
  }

  // Check mark
  .control__indicator::after {
    position: absolute;
    display: none;
    content: "";
  }

  // Checkbox & Radio: Show check mark
  .control input:checked ~ .control__indicator::after {
    display: block;
  }

  // Checkbox: tick
  .control--checkbox .control__indicator::after {
    top: calc((#{$control-indicator-width} - 1.1rem) / 2);
    left: calc((#{$control-indicator-height} - 0.5rem) / 2);
    width: 0.5rem;
    height: 1rem;
    transform: rotate(45deg);
    border: solid #000;
    border-width: 0 2px 2px 0;
  }

  // Checkbox: tick color (disabled-state)
  .control--checkbox input:disabled ~ .control__indicator::after {
    border-color: #7B7B7B;
  }

  // Radio: make radio-background round ( checkbox stays rectangle )
  .control--radio .control__indicator {
    border-radius: 50%;
  }

  .control--radio {
    span {
      display: block;
      margin-top: 4px;
      padding: 0 0.5rem;
    }
  }

  // Radio: button's inner circle
  .control--radio .control__indicator::after {
    background: #000;
    display: block;
    opacity: 0;
    left: calc(#{$control-indicator-width} / 2);
    top: calc(#{$control-indicator-height} / 2);
    width: 0;
    height: 0;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  }

  .control--radio input:checked ~ .control__indicator::after {
    width: $control-radio-dot-width;
    height: $control-radio-dot-height;

    /* left: calc((#{$control-indicator-width} - #{$control-radio-dot-width}) / 2);
    top: calc((#{$control-indicator-height} - #{$control-radio-dot-height}) / 2); */
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  // Radio: on focus -> blue
  .control--radio input:focus ~ .control__indicator::after {
    background: $color-bob-blue;
  }

  // Radio: hover -> not checked
  .control--Radio:hover input:not(:checked) ~ .control__indicator::after {
    width: $control-radio-dot-width;
    height: $control-radio-dot-height;
    left: calc((#{$control-indicator-width} - #{$control-radio-dot-width}) / 2);
    top: calc((#{$control-indicator-height} - #{$control-radio-dot-height}) / 2);
    background: $color-bob-blue;
    opacity: 0.3;
  }

  // Radio: circle color ( disabled-state )
  .control--radio input:disabled ~ .control__indicator::after {
    background: #7B7B7B;
  }
}
