/* Link List
-------------------------------------------------- */

.linklist {
  &__item {
    text-align: center;
    margin-bottom: $content-margin-vertical;

    @include breakpoint(sm) {
      font-size: #{24px / $font-size}rem;
    }
  }
}
