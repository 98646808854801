/* Layout
-------------------------------------------------- */

.main {
  min-height: 400px;
  margin: $module-margin 0 0;

  /* padding-top: 60px; */
  padding-top: 80px;
}

/* Layout Helpers
-------------------------------------------------- */

.hidden,
[hidden] {
  display: none !important;
  visibility: hidden;
}

.no-js .hidden,
.no-js [hidden] {
  display: block;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
