// DOC: http://hugeinc.github.io/flexboxgrid-sass/

@import "../config/variables";
@import "../mixins/flex";
@import "../mixins/breakpoint";

// Set the number of columns you want to use on your layout.
$grid-grid-columns: 12 !default;

// Set the gutter between columns.
$grid-gutter-width: 3rem !default;

// Set a margin for the container sides.
$grid-outer-margin: $grid-gutter-width / 2 !default;

// Set container sizes
$grid-max-width-small: 792px !default;
$grid-max-width: 996px !default;
$grid-max-width-wide: 1200px !default;
$grid-max-width-extra-wide: 1440px !default;

// Syntax: name size
$grid-breakpoints:
  xs 0,
  sm $screen-sm,
  md $screen-md,
  lg $screen-lg !default;

$gutter-compensation: $grid-gutter-width * 0.5 * -1;
$half-gutter-width: $grid-gutter-width * 0.5;

.container {
  position: relative;
  box-sizing: content-box;
  margin: 0 auto;
  padding-left: $grid-outer-margin;
  padding-right: $grid-outer-margin;
  max-width: $grid-max-width;

  &--small {
    max-width: $grid-max-width-small;
  }

  &--fluid {
    max-width: none;
  }

  &--wide {
    max-width: $grid-max-width-wide;
  }

  &--extra-wide {
    max-width: $grid-max-width-extra-wide;
  }
}

.row {
  box-sizing: border-box;

  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);

  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
}

// CLeanfix
.clearfix::after,
.container::after,
.row::after {
  content: "";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
}

.row.reverse {
  @include flex-direction(row-reverse);
}

.col.reverse {
  @include flex-direction(column-reverse);
}

@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;

  // split @include flex(0, 0, auto) into individual props
  @include flex-grow(0);
  @include flex-shrink(0);

  // we leave @include flex-basis(auto) out of common because
  // in some spots we need it and some we dont
  // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126

  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}

$name: xs;

.col-#{$name} {
  @include flexboxgrid-sass-col-common;
  @include flex-basis(auto);
  @include flex-grow(1);
  @include flex-basis(0);

  max-width: 100%;
}

@for $i from 1 through $grid-grid-columns {
  .col-#{$name}-#{$i} {
    @include flexboxgrid-sass-col-common;
    @include flex-basis(100% / $grid-grid-columns * $i);

    max-width: 100% / $grid-grid-columns * $i;
  }
}

@for $i from 0 through $grid-grid-columns {
  .col-#{$name}-offset-#{$i} {
    @include flexboxgrid-sass-col-common;

    @if $i == 0 {
      margin-left: 0;
    }

    @else {
      margin-left: 100% / $grid-grid-columns * $i;
    }
  }
}

.start-#{$name} {
  @include justify-content(flex-start);

  text-align: left;
}

.center-#{$name} {
  @include justify-content(center);

  text-align: center;
}

.end-#{$name} {
  @include justify-content(flex-end);

  text-align: right;
}

.center-#{$name} > *,
.end-#{$name} > * {
  text-align: left;
}

.top-#{$name} {
  @include align-items(flex-start);
}

.middle-#{$name} {
  @include align-items(center);
}

.bottom-#{$name} {
  @include align-items(flex-end);
}

.around-#{$name} {
  @include justify-content(space-around);
}

.between-#{$name} {
  @include justify-content(space-between);
}

.first-#{$name} {
  order: -1;
}

.last-#{$name} {
  order: 1;
}

@each $breakpoint in $grid-breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);

  @media only screen and (min-width : $size) {
    .col-#{$name} {
      @include flexboxgrid-sass-col-common;
      @include flex-basis(auto);
      @include flex-grow(1);
      @include flex-basis(0);

      max-width: 100%;
    }

    @for $i from 1 through $grid-grid-columns {
      .col-#{$name}-#{$i} {
        @include flexboxgrid-sass-col-common;
        @include flex-basis(100% / $grid-grid-columns * $i);

        max-width: 100% / $grid-grid-columns * $i;
      }
    }

    @for $i from 0 through $grid-grid-columns {
      .col-#{$name}-offset-#{$i} {
        @include flexboxgrid-sass-col-common;

        @if $i == 0 {
          margin-left: 0;
        }

        @else {
          margin-left: 100% / $grid-grid-columns * $i;
        }
      }
    }

    .start-#{$name} {
      @include justify-content(flex-start);

      text-align: left;
    }

    .center-#{$name} {
      @include justify-content(center);

      text-align: center;
    }

    .end-#{$name} {
      @include justify-content(flex-end);

      text-align: right;
    }

    .center-#{$name} > *,
    .end-#{$name} > * {
      text-align: left;
    }

    .top-#{$name} {
      @include align-items(flex-start);
    }

    .middle-#{$name} {
      @include align-items(center);
    }

    .bottom-#{$name} {
      @include align-items(flex-end);
    }

    .around-#{$name} {
      @include justify-content(space-around);
    }

    .between-#{$name} {
      @include justify-content(space-between);
    }

    .first-#{$name} {
      order: -1;
    }

    .last-#{$name} {
      order: 1;
    }
  }
}
