/* Icon Benefit List
-------------------------------------------------- */

.icon-benefit-list {
  .row {
    margin-bottom: 0;
  }

  &__item {
    .icon {
      width: 8em;
      height: 8em;
      color: $color-bob-blue;
    }
  }

  &__item + &__item {
    margin-top: $content-margin-vertical;

    @include breakpoint(md) {
      margin-top: 0;
    }
  }
}
