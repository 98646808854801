/* Review
-------------------------------------------------- */

.review {
  .carousel {
    padding-right: 0;
    padding-left: 0;

    &__clip {
      @media (max-width : $grid-max-width - 1) {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &-content {
    &__quote {
      position: relative;
      margin: 0 auto $content-margin-vertical;
      padding: 0;
      font-style: normal;
      font-weight: normal;
      max-width: $grid-max-width-small;

      @include breakpoint(md) {
        //font-size: $font-size-large;
      }
    }

    &__author {
      margin: 0;
      font-weight: bold;
    }

    &__rating {
      margin-bottom: $content-margin-vertical;
    }
  }
}
