/* Icon
-------------------------------------------------- */

.icon {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 0;
  vertical-align: text-top;

  svg {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    fill: currentColor;

    path {
      fill: currentColor;
    }
  }
}
