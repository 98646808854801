@media print {
  @page {
    size: auto;
    margin: 12.7mm;
  }

  html {
    background: none;
    font-size: 8pt;
  }

  body {
    background: #FFF;
    margin: 0;
    padding: 0;
  }

  * {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a:link {
    font-weight: normal;
    white-space: normal;
    word-break: break-all;
    border-color: currentColor;
    color: #06C;
  }

  a:link:not(.logo):not([href*="mailto"]):not([href*="tel"])::after {
    content: " (" attr(href) ") ";
  }

  .container {
    max-width: none;
  }

  .main {
    margin-top: 42mm;

    .module {
      background: none;
      margin-top: 0 !important;
      margin-bottom: 10mm !important;
      padding: 0;
    }
  }

  /*
  .header {
    overflow: visible;
    height: 0;
    padding: 0;
  }

  .header__logo {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: calc(30mm - 2.7mm);
  }
  */

  #cookie-hint,
  .footer,
  .sidebar {
    display: none !important;
  }
}
