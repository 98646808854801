@charset "UTF-8";

@import "config/variables";

/* Document
-------------------------------------------------- */

html {
  background: $background-color;
  position: relative;
  overflow-y: scroll;
  min-height: 100%;
  color: $text-color;
  font-family: $font-family;
  font-size: $font-size;
  line-height: 1.54;
  font-weight: 400;
  font-style: normal;
  cursor: default;
  scroll-behavior: smooth;

  // Avoid page flickering on asynchronously stylesheet load
  opacity: inherit;

  // Disable tap highlighting
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  min-height: 100vh;
}

/* Mixins
-------------------------------------------------- */

@import "mixins/boxshadow";

/* Base Styles
-------------------------------------------------- */

@import "base/reset";
@import "base/fonts";
@import "base/grid";
@import "base/layout";
@import "base/typography";
@import "base/form";

/* Images + Figures
-------------------------------------------------- */

img {
  display: block;
  min-height: 1px;
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
  -ms-interpolation-mode: bicubic;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

figure {
  margin: 0;
}

figure.right {
  float: right;
  margin-left: $content-margin-vertical;
}

figure.left {
  float: left;
  margin-right: $content-margin-vertical;
}

figure.video {
  width: 100%;
}

figure.video iframe,
figure.video object,
figure.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

figure.image {
  margin-bottom: 0.5rem;
}

figcaption {
  background: #FFF;
  padding: 0.5rem 0 0;
  font-size: $font-size-tiny;
  text-align: right;
  color: lighten($text-color, 25);
}

/* Components
-------------------------------------------------- */

// Elements
@import "../components/elements/button/index";
@import "../components/elements/carousel/index";
@import "../components/elements/headline/index";
@import "../components/elements/icon/index";
@import "../components/elements/image/index";
@import "../components/elements/modal/index";
@import "../components/elements/overlayManager/index";
@import "../components/elements/pagination/index";
@import "../components/elements/readmore/index";
@import "../components/elements/tooltip/index";
@import "../components/elements/rating/index";
@import "../components/elements/blogHeader/index";

// Form-Elements
@import "../components/elements/form/inputGroup/index";
@import "../components/elements/form/inputRange/index";
@import "../components/elements/form/select/index";

// Modules
@import "../components/modules/module/index";
@import "../components/modules/breadcrumb/index";
@import "../components/modules/calculator/index";
@import "../components/modules/heroContent/index";
@import "../components/modules/newsletter/index";
@import "../components/modules/search/index";
@import "../components/modules/sidebar/index";
@import "../components/modules/cookieHint/index";
@import "../components/modules/comments/index";

// Templates - top-level content-modules
@import "../components/templates/accordion/index";
@import "../components/templates/contactPerson/index";
@import "../components/templates/faqAccordionsFilter/index";
@import "../components/templates/partnerDownloadsFilter/index";
@import "../components/templates/intro/index";
@import "../components/templates/iconBenefitList/index";
@import "../components/templates/iconlist/index";
@import "../components/templates/itemComparison/index";
@import "../components/templates/linklist/index";
@import "../components/templates/longCopy/index";
@import "../components/templates/newsticker/index";
@import "../components/templates/table/index";
@import "../components/templates/teaserImage/index";
@import "../components/templates/teaserText/index";
@import "../components/templates/textImage/index";
@import "../components/templates/imageGallery/index";
@import "../components/templates/video/index";
@import "../components/templates/quote/index";
@import "../components/templates/blogText/index";
@import "../components/templates/blogTextImage/index";
@import "../components/templates/review/index";
@import "../components/templates/footer/index";
@import "../components/templates/partnerList/index";
@import "../components/templates/tiles/index";
@import "../components/templates/financingcalculator/index";
@import "../components/templates/mainnavigation/index";

// Misc
@import "templates/default";
@import "base/keyframes";
@import "base/aspect-ratio";
@import "base/print";

// Forms (Freeform)
@import "../components/templates/form/index";

// CookieBot
@import "templates/cookiebot.scss";
