/* Headline
-------------------------------------------------- */

@function rem($px) {
  @return #{$px / $font-size}rem;
}

.headline--topline {
  margin: 0;
  font-size: rem(20px);
  font-weight: 400;
  text-align: inherit;

  @include breakpoint(sm) {
    font-size: rem(26px);
  }
}

h1,
.headline--1 {
  font-size: rem(36px);
  font-weight: 700;
  line-height: 1.1;
  text-align: inherit;
  margin-bottom: 1.25rem;

  @include breakpoint(sm) {
    font-size: rem(52px);
    margin-bottom: 2.25rem;
  }
}

h2,
.headline--2 {
  font-size: rem(26px);
  font-weight: 700;
  line-height: 1.15;
  text-align: inherit;
  margin-bottom: 1.25rem;

  @include breakpoint(sm) {
    font-size: rem(46px);
    margin-bottom: 3rem;
  }
}

h3,
.headline--3 {
  font-size: rem(20px);
  font-weight: 700;
  text-align: inherit;
  margin-bottom: 0.5rem;

  @include breakpoint(sm) {
    font-size: rem(24px);
    margin-bottom: 1rem;
  }
}

h4,
.headline--4 {
  font-size: rem(18px);
  font-weight: 700;
  text-align: inherit;
  margin-bottom: 0.25rem;

  @include breakpoint(sm) {
    font-size: rem(22px);
    margin-bottom: 0.5rem;
  }
}

h5,
.headline--5,
h6,
.headline--6 {
  font-size: rem(16px);
  font-weight: 700;
  text-align: inherit;
  margin-bottom: 0.25rem;

  @include breakpoint(sm) {
    margin-bottom: 0.5rem;
  }
}

// Centered
.text--align-center .headline,
.headline.text--align-center {
  margin-left: auto;
  margin-right: auto;
  max-width: $grid-max-width-small;
  text-align: center;
}

// Centered
.text--align-left .headline,
.headline.text--align-left {
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  text-align: left;
}
