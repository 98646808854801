/* Teaser Image
-------------------------------------------------- */

.teaser-image {
  &-list {
    display: flex;

    &__item {
      background: #FFF;
      overflow: hidden;
      display: block;
      height: 100%;
      border-radius: $default-border-radius;
      box-shadow: $default-box-shadow;
      text-decoration: none;
      color: $text-color;

      &:hover {
        transform: scale(1.02);
        transition: transform $transition-box;
      }

      a {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        text-decoration: none;

        &:hover,
        &:focus {
          .headline {
            color: $primary-color;
          }
        }

        figure {
          display: block;
          margin: auto 0 0;
          overflow: hidden;
        }
      }
    }
  }

  &__content {
    padding: $content-box-padding;

    &-link--fake {
      text-decoration: underline;
    }
  }
}
