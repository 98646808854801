/* Select
-------------------------------------------------- */

.select {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: $content-margin-vertical;

  select {
    background: $input-background-color;
    display: inline-block;
    width: 100%;
    border: 1px solid $color-bob-blue-30;
    border-radius: 0.25em;
    min-width: 250px;
    margin: 0;

    /* padding: 0 1.5em; */
    cursor: pointer;
    line-height: calc(3rem - 4px); // - border-width
    height: 3rem;
    white-space: nowrap;
    color: $text-color;
    outline: 0;
    appearance: none;
    padding: 0.45rem 0.75rem;
  }

  select::-ms-expand {
    display: none;
  }

  select:hover,
  select:focus {
    color: $text-link;
    border-color: currentColor;
  }

  select:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    height: 0.933;
    line-height: 0.933;
    pointer-events: none;
    transform: translateY(-50%);
  }

  select:not(:disabled):hover + .icon,
  select:not(:disabled):focus + .icon {
    color: $text-link;
  }

  &--small {
    select {
      min-width: inherit;
      max-width: inherit;
      padding: 0 2.25rem 0 1rem;
      height: 1.9375rem;
      line-height: 1.9375rem;
      font-size: 1rem;
    }
  }
}
