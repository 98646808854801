/* Icon list
-------------------------------------------------- */

.iconlist {
  &-list {
    @include breakpoint(md) {
      justify-content: center;
    }

    &__item {
      text-align: center;
      margin-bottom: $content-margin-vertical;

      @include breakpoint(sm) {
        font-size: #{24px / $font-size}rem;
      }

      @include breakpoint(md) {
        padding-left: 3.5rem;
        text-align: left;
      }

      &-icon {
        display: block;
        width: 2.1rem; // 1.4em
        height: 2.1rem; // 1.4em
        vertical-align: middle;
        color: $color-bob-blue;
        margin: 0 auto $content-margin-vertical / 2;

        @include breakpoint(md) {
          position: absolute;
          display: inline-block;
          margin: 0.35em 0 0 -2em;
        }
      }
    }
  }
}
