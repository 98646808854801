/* Footer
-------------------------------------------------- */

.container--newsletter {
  background: white;
  padding-top: $module-padding;
}

.footer {
  position: relative;
  padding-top: $module-padding / 2;
  padding-bottom: $module-padding / 2;
  z-index: $stack-order-footer;
  background-color: $color-bob-blue;

  @include breakpoint(md) {
    padding-top: $module-padding;
    padding-bottom: $module-padding;
  }

  .col-logo {
    display: flex;
    flex-direction: column;

    .anchor {

      /* margin: 0 auto 0 auto;

      img {
        margin: 0 auto;
      } */

      @include breakpoint(md) {
        margin: 0 0 auto 0;
      }
    }
  }

  // Footer Navigation
  &-nav {
    background-color: $color-bob-blue;

    .row + .row {
      margin-top: calc(#{$module-padding / 2} - 1rem); // 1 rem = nav-item padding
    }

    &__list {
      display: flex;
      justify-content: center;
      margin-bottom: 0;

      &--partner {
        flex-wrap: wrap;
      }

      &-item {
        &-link {
          display: inline-block;
          margin: 1rem;
          height: 32px; // IE 11 fix

          img {
            height: 32px;
            width: auto;
          }
        }
      }

      &--trust {
        margin-top: 0;
        justify-content: center;

        @include breakpoint(md) {
          justify-content: flex-end;
          margin-top: -$content-margin-vertical;
        }
      }

      &--trust &-item {
        &-link {
          height: auto;

          img {
            max-height: 90px;
            height: auto;
          }
        }
      }

      &-item + &-item {
        @include breakpoint(md) {
          margin-left: 0.5rem;
        }
      }
    }
  }

  // Social Navigation
  &-social-nav {
    margin: auto 0 0 0;

    &__list {
      margin: 0 0 ($module-padding / 2);
      text-align: center;

      @include breakpoint(md) {
        margin: -0.5rem -0.5rem 0 0;
        text-align: left;
      }

      &-item {
        display: inline-block;

        &-link {
          display: inline-block;
          color: $color-bob-white;

          &:hover {
            color: $text-color;
          }

          .icon {
            width: 2.5em;
            height: 2.5rem;
            padding: 0.5rem;
          }
        }
      }
    }
  }

  // Footer Menu
  &-menu-nav__list {
    margin-bottom: 0;

    &--item {
      color: $color-bob-white;

      &.main-area {
        font-weight: bold;
      }
    }
  }
  // Meta navigation
  &-meta-nav {
    background-color: $color-bob-blue;
    margin-top: calc(#{$module-padding / 2} - 1rem); // 1 rem = nav-item padding
    margin-bottom: $module-padding / 2;
    padding: 1rem 0;
    text-align: center;

    @include breakpoint(md) {
      margin-top: calc(#{$module-padding} - 1rem); // 1 rem = nav-item padding
      margin-bottom: $module-padding;
    }

    @include breakpoint(md) {
      text-align: left;
    }

    &__list {
      margin: 0;
      padding: 1rem 0;

      &-item {
        color: $color-bob-white;

        @include breakpoint(md) {
          display: inline-block;
        }

        a {
          display: block;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }

      &-item + &-item {
        margin: 0.75rem 0 0;

        @include breakpoint(md) {
          margin: 0 0 0 1rem;
        }
      }
    }
  }

  // Legal
  &-legal {
    background-color: $color-bob-blue;
    color: $secondary-color;
    color: white;

    & > .container {
      border-top: 2px solid $color-bob-blue-90;
    }

    &__copy {
      margin-top: $content-margin-vertical;
    }
  }
}

.breadcrumb + .footer {
  padding-top: 0;
}
