@import "../config/variables";

/**
 * Box Shadow mixin
 *
 * $parameter: box-shadow parameter without color
 * $color: box-shadow color
 *
 * Usage:
 * box-shadow(0 2px 15px 3px, rgba(0,0,0,0.14));
 */
@mixin box-shadow($parameter: $colorBoxShadowDefaultParameter, $color: $colorBoxShadowDefaultColor) {
  filter: progid:dximagetransform.microsoft.shadow(color="#999999", Direction=90, Strength=3) progid:dximagetransform.microsoft.shadow(color="#999999", Direction=135, Strength=3) progid:dximagetransform.microsoft.shadow(color="#999999", Direction=180, Strength=3);
  -webkit-box-shadow: $parameter $color;
  -moz-box-shadow: $parameter $color;
  box-shadow: $parameter $color;
}
