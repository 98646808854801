/* Aspect Ratio
-------------------------------------------------- */

[data-aspect-ratio] {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    height: 0;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

[data-aspect-ratio^="16:9"]::before,
[data-aspect-ratio*="16:9-xs"]::before {
  padding-top: (9 / 16) * 100%;
}

[data-aspect-ratio^="4:3"]::before,
[data-aspect-ratio*="4:3-xs"]::before {
  padding-top: (3 / 4) * 100%;
}

[data-aspect-ratio^="2:1"]::before,
[data-aspect-ratio*="2:1-xs"]::before {
  padding-top: (1 / 2) * 100%;
}

[data-aspect-ratio^="1:1"]::before,
[data-aspect-ratio*="1:1-xs"]::before {
  padding-top: 100%;
}

@media (min-width : $screen-sm) {
  [data-aspect-ratio*="16:9-sm"]::before {
    padding-top: (9 / 16) * 100%;
  }

  [data-aspect-ratio*="4:3-sm"]::before {
    padding-top: (3 / 4) * 100%;
  }

  [data-aspect-ratio*="2:1-sm"]::before {
    padding-top: (1 / 2) * 100%;
  }

  [data-aspect-ratio*="1:1-sm"]::before {
    padding-top: 100%;
  }
}

@media (min-width : $screen-md) {
  [data-aspect-ratio*="16:9-md"]::before {
    padding-top: (9 / 16) * 100%;
  }

  [data-aspect-ratio*="4:3-md"]::before {
    padding-top: (3 / 4) * 100%;
  }

  [data-aspect-ratio*="2:1-md"]::before {
    padding-top: (1 / 2) * 100%;
  }

  [data-aspect-ratio*="1:1-md"]::before {
    padding-top: 100%;
  }
}

@media (min-width : $screen-lg) {
  [data-aspect-ratio*="16:9-lg"]::before {
    padding-top: (9 / 16) * 100%;
  }

  [data-aspect-ratio*="4:3-lg"]::before {
    padding-top: (3 / 4) * 100%;
  }

  [data-aspect-ratio*="2:1-lg"]::before {
    padding-top: (1 / 2) * 100%;
  }

  [data-aspect-ratio*="1:1-lg"]::before {
    padding-top: 100%;
  }
}
