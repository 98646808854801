/* Text Image
-------------------------------------------------- */

.text-image {
  &__image {
    position: relative;
    padding: 0;
    margin-bottom: $content-margin-vertical;

    @include breakpoint(md) {
      padding: 0 #{$grid-outer-margin};
      margin-bottom: 0;
    }

    .background-image {
      background-size: contain;
      background-position: bottom right;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__content {
    //padding-bottom: 6rem;
    padding-bottom: 0;

    @include breakpoint(md) {
      //padding-top: 6rem;
      padding-top: 0;
    }

    .partnerlist {
      &-list {
        margin: ($content-margin-vertical * 2) 0 1.25rem;
        justify-content: center;

        @include breakpoint(md) {
          justify-content: center;
        }

        &__item {

          /* text-align: center; */
          margin-bottom: $content-margin-vertical;
          padding-left: 0;
          text-align: left;

          @include breakpoint(sm) {
            font-size: #{24px / $font-size}rem;
          }

          /*
          @include breakpoint(md) {

          }
*/
        }
      }
    }
  }

  &--image-with-background-gradient {
    @include breakpoint(md) {
      background-image: linear-gradient(90deg, $color-bob-blue-30 50%, transparent 50%);

      &.text-image--image-right {
        background-image: linear-gradient(90deg, transparent 50%, $color-bob-blue-30 50%);
      }
    }
  }

  &--image-right {
    .text-image-wrapper {
      @include breakpoint(md) {
        flex-direction: row-reverse;
      }
    }

    .text-image__content {
      @include breakpoint(md) {
        padding-right: $grid-gutter-width; // Give em a ittle extra space
      }
    }

    .text-image__image {
      .background-image {
        background-position: bottom left;
      }
    }
  }

  &--image-left {
    .text-image__content {
      @include breakpoint(md) {
        padding-left: $grid-gutter-width; // Give em a ittle extra space
      }
    }
  }
}
