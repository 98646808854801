/* global */

/* header */
.header {
  display: block;
  width: 100%;
  position: fixed;
  z-index: 99;
  padding: 15px;
  background: #FFF;

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .v-center {
    align-items: center;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  /* Logo */
  .logo-row {
    flex: 0 0 17%;

    a {
      img {
        max-width: 180px;
      }
    }
  }

  .menu-row {
    flex: 0 0 66%;
    margin-top: 8px;

    .header-menu__button-login {
      display: none;
    }

    .menu > ul > li {
      display: inline-block;
      line-height: 50px;
      margin-left: 25px;
      position: relative;

      > a {
        font-weight: 500;
        color: #000;
        position: relative;
        //text-transform: capitalize;
        transition: color 0.3s ease;

        &:hover {
          cursor: pointer;
          color: #0091B2;
        }

        &.is-active {
          color: #0091B2;
        }
      }

      .sub-menu {
        position: absolute;
        z-index: 500;
        background-color: #FFF;
        box-shadow: 0 4px 12px 0 #3C7A8A;
        transition: all 0.5s ease;
        margin-top: 15px;
        display: none;
        left: 50%;
        transform: translateX(-50%);
        width: 260px;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 25px 30px;

        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 2px;
          left: 50%;
          box-sizing: border-box;
          border: 10px solid black;
          border-radius: 2px;
          border-color: transparent transparent #FFF #FFF;
          transform-origin: 0 0;
          transform: translateX(50%) rotate(135deg);
          box-shadow: -3px 3px 3px 0 rgba(60, 122, 138, 0.2);
        }

        > .list-item {
          width: 100%;
          font-size: 16px;
          font-weight: 500;
          line-height: 40px;

          .toggle {
            display: none;
          }

          &.is-active {
            color: #0091B2;
          }

          & + .arrow {
            display: none;
          }
        }
      }
    }

    .is-active {
      color: #0091B2;
    }
  }

  .meta-row {
    flex: 0 0 17%;
    display: flex;
    justify-content: flex-end;

    a {
      text-decoration: none;
      font-size: 16px;
      color: #555;
      display: inline-block;
      margin-left: 10px;
      transition: color 0.3s ease;

      &.button-login--mobile {
        display: none;
      }
    }
  }
}

.mobile-menu-head,
.mobile-menu-trigger {
  display: none;
}

/* adjustments for screens > 991 */
@media (min-width : 992px) {
  .header .menu > ul > li.menu-item-has-children:hover .sub-menu {
    margin-top: 0;
    display: flex;
  }

  .header .menu > ul > li.menu-item-has-children .lvl-1 .arrow,
  .arrow {
    display: none;
  }
}

/* screen < 991 */
@media (max-width : 991px) {
  @keyframes slideLeft {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes slideRight {
    0% {
      opacity: 1;
      transform: translateX(0%);
    }

    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  .header {
    .mobile-menu-trigger {
      display: flex;
      height: 30px;
      width: 30px;
      margin-left: 15px;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      span {
        display: block;
        height: 2px;
        background-color: #333;
        width: 24px;
        position: relative;

        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #333;
        }

        &::before {
          top: -6px;
        }

        &::after {
          top: 6px;
        }
      }
    }

    .menu-row {
      order: 3;
      flex: 0 0 100%;

      .header-menu__button-login {
        display: block;
        width: 90%;
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
      }

      .meta-row {
        align-items: center;
      }

      .menu {
        position: fixed;
        background-color: #FFF;
        right: 0;
        top: 0;
        width: 80%;
        height: 100%;
        overflow: hidden;
        transform: translate(100%);
        transition: all 0.5s ease;
        z-index: 1099;

        &.active {
          transform: translate(0%);
        }

        .menu-item-has-children {
          &::before {
            position: absolute;
            right: 0;
            top: 1rem;
            border: solid #000;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
          }

          a {
            span {
              position: absolute;
              display: inline-block;
              margin: 0.45em 0 0 -2em;
              height: 1em;
              right: 15px;
              color: $primary-color;
            }
          }
        }

        > ul > li {
          line-height: 1;
          margin: 0;
          display: block;
          position: initial;

          > a {
            line-height: 50px;
            height: 50px;
            padding: 0 15px;
            display: block;

            &::after {
              content: "";
              width: 100%;
              height: 1px;
              display: block;
              background: #C6C6C6;
            }
          }

          &:last-child {
            > a {
              &::after {
                content: "";
                width: 100%;
                height: 1px;
                display: block;
                background: #FFF;
              }
            }
          }
        }

        .mobile-menu-head {
          display: flex;
          height: 50px;
          justify-content: space-between;
          align-items: center;
          z-index: 501;
          position: sticky;
          background-color: #FFF;
          top: 0;

          .go-back {
            height: 50px;
            width: 50px;
            cursor: pointer;
            line-height: 50px;
            text-align: center;
            color: #000;
            font-size: 25px;
            display: none;
          }

          &.active .go-back {
            display: block;
          }

          .current-menu-title {
            font-size: 15px;
            font-weight: 500;

            a {
              font-size: 16px;
              color: #0091B2;
              font-weight: 500;

              & > span {
                display: none;
              }
            }
          }

          .mobile-menu-close {
            height: 50px;
            width: 50px;
            cursor: pointer;
            line-height: 50px;
            text-align: center;
            color: #000;
            font-size: 25px;

            span {
              position: absolute;
              display: inline-block;
              margin: 0;
              height: 1em;
              right: 15px;
            }
          }
        }

        .menu-main {
          height: 100%;
          overflow-x: hidden;
          overflow-y: auto;
        }

        > ul > li .sub-menu {
          visibility: visible;
          opacity: 1;
          position: absolute;
          box-shadow: none;
          margin: 0;
          padding: 15px 20px 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          max-width: none;
          min-width: auto;
          display: none;
          transform: translateX(0%);
          overflow-y: auto;
          padding-top: 65px;

          &.active {
            display: block;
            height: auto;

            .list-item {
              position: relative;
              display: inline-block;
              font-size: 16px;
              line-height: 160%;
              padding: 16px 0;
              border-bottom: 1px solid #C6C6C6;

              &:last-child {
                border-bottom: 0;
              }
            }
          }
        }
      }
    }

    .logo-row {
      flex: 0 0 50%;
    }

    .meta-row {
      flex: 0 0 50%;

      a {
        &.button-login--mobile {
          display: block;
        }

        &.button-login--desktop {
          display: none;
        }
      }
    }
  }

  .v-center {
    justify-content: space-between;
  }

  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
}

/* helpers */
.menu-row {
  a:hover {
    cursor: pointer !important;
    color: #0091B2 !important;
  }
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #FFF transparent;
  transform: rotate(0deg);
  border-radius: 2px;
}
