/* Configuration
-------------------------------------------------- */

// Breakpoints
$screen-xs: 600px !default;
$screen-sm: ($screen-xs + 1) !default;
$screen-md: 940px !default;
$screen-lg: 1200px !default;

// Layout
//$header-transform: 1200px !default;
$header-transform: 1100px !default;
$module-margin: 0 !default;
$module-padding: 5rem !default;
$default-border-radius: 0.25rem !default;
$default-box-shadow-blur: 1rem !default;
$default-box-shadow: 0 0 $default-box-shadow-blur rgba(0, 0, 0, 0.08) !default;
$content-box-padding: 1.25rem !default;
$content-margin-vertical: 1.25rem !default;
$ui-element-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2) !default;

// Colors
$background-color: #FFF !default;
$text-color: #000 !default;
$text-link: #0091B2 !default;
$primary-color: #0091B2 !default;
$secondary-color: #8F959B !default;
$input-background-color: #E6F4F7 !default;
$input-placeholder-color: #8B8B8A !default;
$freeform-error-color: #AA4442 !default;

// Brand colors
$color-bob-white: #FFF !default;
$color-bob-black: #000 !default;
$color-bob-grey: #8B8B8A !default;
$color-bob-dark: #1D1D1B !default;
$color-bob-blue: #0091B2 !default;
$color-bob-blue-30: #4DB2C9 !default; // dark-blue
$color-bob-blue-60: #99D3E0 !default; // blue
$color-bob-blue-90: #E6F4F7 !default; // light-blue
$color-bob-yellow: #FFC53D !default;
$color-bob-yellow-hover: #F0A804; // hover state for yellow buttons etc.

// Typography
$font-family: "Foundry", "Arial", sans-serif !default;
$font-size: 16px !default;
$font-size-tiny: 12px !default;
$font-size-large: 28px !default;

// Stack order
$stack-order-footer: 800 !default;
$stack-order-sidebar: 810 !default;
$stack-order-header: 900 !default;
$stack-order-tooltip: 910 !default;
$stack-order-cookieHint: 920 !default;
$stack-order-modal: 990 !default;

// Transitions
$transition-menu: 0.3s ease-in-out !default;
$transition-modal: 0.3s cubic-bezier(0, 0, 0.2, 1) !default;
$transition-module: 0.75s cubic-bezier(0.15, 0, 0, 1) 0.15s !default;
$transition-box: 0.3s ease-in !default;
$transition-ui-element: 0.3s ease-out !default;
