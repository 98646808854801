/* Long Copy
-------------------------------------------------- */

.long-copy {
  &.module-longcopy-padding-bottom-reduced {
    padding-bottom: $module-padding / 4;
  }

  &--image-right {
    .row {
      @include breakpoint(md) {
        flex-direction: row-reverse;
      }
    }
  }

  &__image {
    margin-bottom: $content-margin-vertical;

    @include breakpoint(md) {
      margin-bottom: 0;
    }
  }
}
