/* Accordion
-------------------------------------------------- */

.accordion {
  &.module-accordion-padding-bottom-reduced {
    padding-bottom: $module-padding / 4;
  }

  &-item {
    padding: 1rem 1rem 1rem calc(1rem + 2em);
    border-bottom: 2px solid $color-bob-blue-30;

    &.download-item {
      position: relative;
    }

    &:first-of-type {
      border-top: 2px solid $color-bob-blue-30;
    }

    &__header {
      position: relative;
      display: block;
      cursor: pointer;

      &-icon {
        position: absolute;
        display: inline-block;
        margin: 0.45em 0 0 -2em;
        transform: rotate(180deg);
        height: 1em;
      }

      &-headline {
        display: inline;

        &.reduced-headline {
          font-size: 1rem;

          @include breakpoint(sm) {
            font-size: 1.25rem;
          }
        }
      }
    }

    &__download {
      &-icon {
        position: absolute;
        margin: 0;
        right: 4%;
        top: calc(50% - 0.75rem);
        width: 22px;
        height: 22px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__header:hover,
    &__download-icon:hover,
    input[name="accordion-checkbox"]:checked + &__header {
      color: $primary-color;
    }

    input[name="accordion-checkbox"]:checked + &__header {
      .accordion-item__header-icon {
        transform: rotate(0deg);
      }
    }

    &__body {
      display: none;
      margin-top: 0.75rem;

      &-downloads {
        text-align: center;

        .text--richtext {
          text-align: left;

          &:first-of-type {
            margin-bottom: 0;
          }
        }

        img {
          margin: 0 auto 1em auto;
          width: 150px;
        }
      }
    }

    input[name="accordion-checkbox"]:checked + &__header + &__body {
      display: block;
    }

    input[name="accordion-checkbox"]:checked + &__header + &__body + &__download-icon {
      display: none;
    }
  }

  &.module-background--dark-blue {
    .accordion-item__header:hover,
    input[name="accordion-checkbox"]:checked + .accordion-item__header {
      color: $color-bob-yellow;
    }
  }

  &__button-group {
    padding-top: $content-margin-vertical;
  }

  &-intro_copy {
    padding: 0 0 1.5rem 0;

    &--small {
      max-width: $grid-max-width-small;
      padding-bottom: 1.5rem;
      margin: 0 auto;
    }
  }

  &--wide {
    .accordion-item {
      &__body {
        margin-left: calc(-1 * (1rem + 2em));
      }
    }
  }
}

// Accordion states (expand option)
.no-js .accordion__button-group,
.js .accordion--js:not(.accordion--is-expanded) .accordion-item--is-hidden {
  display: none;
}

// Accordion states (filter option)
.is-active-filter .accordion[data-filter-id]:not(.accordion--is-active) {
  display: none;
}
