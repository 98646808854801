/* Calculator
-------------------------------------------------- */

.comments {
  .cc-w {
    color: inherit;
    font-size: inherit;

    a {
      color: $text-link;
    }

    small {
      font-size: 1rem;
    }

    .cc-i {
      margin: 0;
      padding: 0;
      border: 0;
    }

    [data-role="content"] {
      background: #FFF;
      margin: 0 0 $content-margin-vertical;
      padding: $content-box-padding $content-box-padding 0.75rem;
      border-radius: $default-border-radius;
    }

    [data-role="replies"] [data-role="content"] {
      background: none;
      border-radius: 0;
    }

    &.no-avatar .cc-i .cc-i {
      margin-left: 0;

      @include breakpoint(sm) {
        margin-left: 1.5rem;
      }
    }

    .cc-i-header,
    .cc-i-footer {
      font-size: 1rem;
    }

    .cc-i-header {
      // display: block;
      .cc-icon svg {
        width: 20px;
        height: 20px;
        color: inherit;
        opacity: 0.5;
      }

      [itemprop="creator"] {
        .cc-i-author {
          background: #00B4CC;
          color: #FFF;
          font-weight: normal;
          padding: 0.5em 0.75em;
          border-radius: 1em;
        }
      }

      .cc-ll-i::before {
        content: "";
        margin-left: 0.5rem;
      }
    }

    .cc-i-body {
      margin: $content-margin-vertical 0;
      padding: 0;
    }

    .cc-i-footer .cc-ll-i::before {
      content: none;
    }

    .cc-e {
      font-size: inherit;
    }
  }

  .cc-i-wrap {
    .cc-f-wrap {
      text-align: right;

      .cc-f-row {
        margin: 3rem -1.5rem 0;
      }

      .cc-f-col {
        margin: 0 0 $content-margin-vertical;
        padding: 0 1.5rem;
      }

      .cc-f-input,
      .cc-f-textarea {
        background: #FFF;
        border: 2px solid transparent;
        outline: none;
        margin: 0;
        padding: 0.65em $content-box-padding;
        line-height: 1.2;
        line-height: inherit;
        font-size: 1rem;
        border-radius: 2em;

        &:hover,
        &:focus {
          border-color: $color-bob-blue;
        }
      }

      .cc-f-textarea {
        min-height: 9em;
      }

      .cc-f-btn {
        background-color: $color-bob-blue;
        position: relative;
        overflow: hidden;
        display: inline-block;
        border: 2px solid transparent;
        border-radius: 2em;
        min-width: 250px;
        margin: $content-margin-vertical 0 $content-margin-vertical;
        padding: 0.5em 1em;
        font-family: $font-family;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.2 !important;
        color: #FFF;
        text-decoration: none;
        text-transform: none;
        text-align: center;
        vertical-align: middle;
        word-wrap: normal;
        user-select: none;
        cursor: pointer;
        -webkit-touch-callout: none;
        appearance: none;
        box-shadow: $ui-element-box-shadow;
        transition: background-color $transition-ui-element, box-shadow $transition-ui-element;

        &:hover,
        &:focus {
          background-color: lighten($color-bob-blue, 5%);
          box-shadow: none;
        }
      }
    }
  }
}
