/* Video
-------------------------------------------------- */

.video {
  &-content {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;

    &__iframe {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}
