/* Fonts
-------------------------------------------------- */

html {
  // Prevent adjustments of font size after orientation changes in IE and iOS
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-size-adjust: none;
}

* {
  -webkit-font-smoothing: antialiased;
}

/* Custom Web Fonts
-------------------------------------------------- */

// Regular, 400
@font-face {
  font-family: "Foundry";
  src: url("../fonts/FoundryContext-Regular.eot?#iefix");
  src: url("../fonts/FoundryContext-Regular.eot?#iefix") format("eot"),
    url("../fonts/FoundryContext-Regular.woff2") format("woff2"),
    url("../fonts/FoundryContext-Regular.woff") format("woff"),
    url("../fonts/FoundryContext-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

// Medium, 500
@font-face {
  font-family: "Foundry";
  src: url("../fonts/FoundryContext-Medium.eot?#iefix");
  src: url("../fonts/FoundryContext-Medium.eot?#iefix") format("eot"),
    url("../fonts/FoundryContext-Medium.woff2") format("woff2"),
    url("../fonts/FoundryContext-Medium.woff") format("woff"),
    url("../fonts/FoundryContext-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

// Bold, 700
@font-face {
  font-family: "Foundry";
  src: url("../fonts/FoundryContext-Bold.eot?#iefix");
  src: url("../fonts/FoundryContext-Bold.eot?#iefix") format("eot"),
    url("../fonts/FoundryContext-Bold.woff2") format("woff2"),
    url("../fonts/FoundryContext-Bold.woff") format("woff"),
    url("../fonts/FoundryContext-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
