/* Item Comparison
-------------------------------------------------- */

.item-comparison {
  &__list {
    &-item {
      max-width: none;

      @include breakpoint(md) {
        max-width: 50%;
      }
    }
  }

  .compare-item {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: $content-box-padding;
    border: 2px solid transparent;
    text-align: left;
    white-space: normal;
    word-break: break-word;
    hyphens: auto;

    @include breakpoint(md) {
      flex-direction: column;
      text-align: center;
    }

    &--highlight {
      background-color: #FFF;
      border-color: $color-bob-blue;
      border-radius: $default-border-radius;
    }

    &__header {
      width: 20vw;
      max-width: 140px;

      .headline {
        text-align: left;
      }

      @include breakpoint(md) {
        width: auto;
        max-width: none;

        .headline {
          text-align: center;
        }
      }

      &-subline {
        margin-bottom: 0.5rem;
        font-size: 1rem;
      }
    }

    &__feature-list {
      display: flex;
      margin: 0;
      padding: 0 $content-box-padding;

      @include breakpoint(md) {
        display: block;
        margin-bottom: $content-margin-vertical;
        padding: 0;
      }

      &-item {
        text-align: left;
        width: 30vw;
        max-width: 220px;
        padding-left: 0.5em;

        @include breakpoint(sm) {
          font-size: #{24px / $font-size}rem;
        }

        @include breakpoint(md) {
          width: auto;
          max-width: none;
          margin-top: $content-margin-vertical;
          padding-left: 2em;
        }

        &-icon {
          display: block;
          width: 1.4em; // IE 11
          height: 1.4em;
          margin-bottom: 0.5em;
          text-align: center;
          color: $color-bob-blue;

          @include breakpoint(md) {
            position: absolute;
            display: inline-block;
            margin: 0.35em 0 0 -2em;
          }

          &--uncheck {
            padding: 0.1em;
          }
        }

        &--excluded {
          opacity: 0.5;
        }
      }
    }

    &__button-group {
      margin: auto;

      @include breakpoint(md) {
        margin: 0;
      }
    }
  }
}
