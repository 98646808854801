/* Module
-------------------------------------------------- */

.module {
  background-color: $color-bob-white;
  margin-top: $module-margin / 2;
  margin-bottom: $module-margin / 2;
  padding-top: $module-padding / 2;
  padding-bottom: $module-padding / 2;

  @include breakpoint(md) {
    margin-top: $module-margin;
    margin-bottom: $module-margin;
    padding-top: $module-padding;
    padding-bottom: $module-padding;
  }

  &-padding-bottom-reduced {
    padding-bottom: $module-padding / 4;
  }

  &-padding-top-reduced {
    padding-top: $module-padding / 4;
  }

  // if you add a color here, make sure to add it as well in the translation-file `/translations/de/site.php`
  &-background--light-blue {
    background-color: $color-bob-blue-90;
  }

  &-background--blue {
    background-color: $color-bob-blue-30;
    color: #FFF;
  }

  &-background--dark-blue {
    background-color: $color-bob-blue;
    color: #FFF;
  }

  &-background--yellow {
    background-color: $color-bob-yellow;
  }

  // Nested modules
  .module {
    background-color: transparent;
    margin: 0;
    padding: 0;
  }
}

// Reset module padding
.module:not([class*="module-background--"]) + .module:not([class*="module-background--"]),
.module-background-- + .module-background--, // Fallback
.module-background-- + .module-background--white, // Fallback
.module-background--white + .module-background--white,
.module-background--light-blue + .module-background--light-blue,
.module-background--blue + .module-background--blue,
.module-background--dark-blue + .module-background--dark-blue {
  padding-top: 0;
}

// Module transition
.js {
  .module:not(.hero-content) > * {
    opacity: 0;
    transform: translateY(4rem);
  }

  .module:not(.hero-content).module--is-visible > * {
    opacity: 1;
    transform: translateY(0);
    transition: opacity $transition-module, transform $transition-module;
  }

  .module.hero-content > * {
    opacity: 0;
  }

  .module.hero-content.module--is-visible > * {
    opacity: 1;
    transition: opacity $transition-module;
  }
}
