/* Quote Styles */

.quote {
  blockquote {
    font-size: rem(24px);
    font-weight: 400;

    @include breakpoint(sm) {
      font-size: rem(28px);
    }

    cite {
      display: block;
      font-size: rem(18px);
      font-style: normal;
      color: $primary-color;
      margin-top: 0.5rem;
    }
  }
}
