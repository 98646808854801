/* Breadcrumb
-------------------------------------------------- */

.breadcrumb {
  position: absolute;
  top: 0;
  left: 0;

  /* background: #FFF; */

  /* position: relative; */
  width: 100%;
  white-space: nowrap;

  .container {

    /* max-width: 100%; */
  }

  &-list {
    margin: 0;
    padding: $module-padding 0 2rem; // A little scrollbar space at the bottom

    &__item {
      display: inline-block;
      line-height: 1;
      vertical-align: middle;
      color: $color-bob-dark;

      @include breakpoint(sm) {
        font-size: 1.25rem;
        font-size: 1rem;
      }

      &:last-child {
        margin-right: $grid-outer-margin;
      }

      &:not(:last-child) {
        color: $text-link;

        &:hover {
          color: $color-bob-dark;
        }
      }

      &-icon {
        position: relative;
        display: inline-block;
        margin: 0 0.4em;
        vertical-align: middle;
        line-height: 0;
        width: 1em;
        height: 1em;
        color: $text-link; // Always blue

        &--home {
          margin-left: 0;
        }
      }

      &-link {
        text-decoration: none;
      }
    }
  }

  &--is-nested {
    white-space: normal;
    position: relative !important;

    .breadcrumb-list {
      padding: 0 0 $content-margin-vertical;

      &__item {
        display: inline;
        color: $color-bob-dark;
        font-size: 1em;
        vertical-align: baseline;

        &-icon {
          color: currentColor;
        }
      }
    }
  }
}
