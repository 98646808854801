/* Table
-------------------------------------------------- */

.table {
  &-root {
    width: 100%;
    border-collapse: collapse;

    thead {
      color: $color-bob-blue;
      white-space: nowrap;
    }

    th,
    td {
      padding: 0.5rem 1rem;
      border-bottom: 2px solid $color-bob-blue-60;
    }

    tbody td:first-child {
      font-weight: bold;
    }
  }
}
