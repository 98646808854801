/* Modal
-------------------------------------------------- */

.modal {
  &__overlay {
    background: rgba($color-bob-dark, 0.35);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $stack-order-modal;

    > .container {
      width: 100%;
    }
  }

  &__container {
    background: #FFF;
    position: relative;
    width: 100%;
    max-height: 100vh;
    padding: ($content-box-padding * 3) $content-box-padding ($content-box-padding * 2 - $content-margin-vertical);
    box-shadow: $default-box-shadow;
    border-radius: $default-border-radius;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @include breakpoint(md) {
      padding-right: $content-box-padding * 2;
      padding-left: $content-box-padding * 2;
    }
  }

  &__header {
    text-align: center;
  }

  &__close {
    position: absolute;
    top: $content-box-padding;
    right: $content-box-padding;
    display: inline-block;
    color: $text-color;
    font-size: 1.75rem;
    cursor: pointer;

    &:hover {
      color: $text-link;
    }

    .icon {
      pointer-events: none;
    }
  }

  &__content {
    // Reset nested container
    .container {
      padding-right: 0;
      padding-left: 0;
    }

    .module {
      margin: 0;
      padding: 0;
    }
  }
}

.modal-slide {
  display: none;

  &.is-open {
    display: block;
  }

  &[aria-hidden="false"] {
    .modal__overlay {
      animation: fadeIn $transition-modal;
    }

    .modal__container {
      animation: slideIn $transition-modal;
    }
  }

  &[aria-hidden="true"] {
    .modal__overlay {
      animation: fadeOut $transition-modal;
    }

    .modal__container {
      animation: slideOut $transition-modal;
    }
  }

  .modal__container,
  .modal__overlay {
    will-change: transform;
  }
}
