/* newsticker
-------------------------------------------------- */

.newsticker {
  &__item {
    margin-bottom: 1.75rem;

    &-topline {
      margin-bottom: 0.5rem;
    }

    &-image {
      @media (max-width : $screen-md) {
        margin-bottom: 1.5rem;
        max-height: 210px;
        display: inline-block;
      }
    }

    &-copy {
      margin-bottom: 0.5rem;
    }

    &-button--more {
      color: $color-bob-blue;
      box-shadow: none;
    }

    &--is-topnews {
      // background: rgba(0, 0, 0, 0.1);
    }
  }
}
