// Button

.button-group {
  position: relative;
  margin: ($content-margin-vertical / 2) #{-($content-margin-vertical / 2)} #{-($content-margin-vertical / 2)};

  .button {
    margin: ($content-margin-vertical / 2);
  }

  &--vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.button {
  background-color: $color-bob-blue;
  position: relative;
  overflow: hidden;
  display: inline-block;
  border: 2px solid transparent;
  min-width: 250px;
  margin: 0;
  padding: 0.5em 1em;
  font-family: $font-family;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.2 !important;
  color: #FFF;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  word-wrap: normal;
  user-select: none;
  border-radius: 0.25em;
  cursor: pointer;
  -webkit-touch-callout: none;
  appearance: none;
  box-shadow: $ui-element-box-shadow;
  transition: background-color $transition-ui-element, box-shadow $transition-ui-element;

  &:hover,
  &:focus {
    background-color: lighten($color-bob-blue, 5%);
    box-shadow: none;
  }

  &--secondary {
    background-color: transparent;
    color: $color-bob-blue;
    border-color: currentColor;

    &:hover,
    &:focus {
      background-color: rgba($color-bob-blue, 0.09);
    }
  }

  &--primary {
    background-color: $color-bob-yellow;
    color: $text-color;
    border-color: transparent;

    &:hover,
    &:focus {
      background-color: $color-bob-yellow-hover;
    }
  }

  &--primary-dark {
    background-color: $color-bob-dark;
    color: #FFF;
    border-color: transparent;

    &:hover,
    &:focus {
      background-color: lighten($color-bob-dark, 10%);
    }
  }

  &--tag {
    background-color: rgba($color-bob-blue, 0.6);
    min-width: auto;
    font-size: 0.933rem;

    @include breakpoint(md) {
      font-size: 1rem;
    }
  }

  :checked ~ &--tag,
  :not(:checked) ~ &--tag:hover {
    background-color: $color-bob-blue;
  }

  &--nowrap {
    padding: 0 1rem;
    line-height: 2.75rem !important;
    height: 2.75rem;
    white-space: nowrap;
  }

  &--small {
    min-width: 0;
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
  }

  &--download {
    min-width: 0;
  }

  &--plain {
    background: none;
    padding: 0;
    border: none;
    min-width: 0;
    text-decoration: underline;
    border-radius: 0;
    color: inherit;

    &:hover,
    &:focus {
      background: none;
    }
  }

  &--round {
    display: inline-flex;
    min-width: auto;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
  }

  .icon {
    margin: 0;
    line-height: 0;
    vertical-align: text-bottom;
  }

  .icon + &__label {
    margin-left: 0.4em;
  }

  > * {
    pointer-events: none;
  }
}

.module-background--blue,
.module-background--dark-blue {
  .button {
    &:not(.button--secondary):not(.button--primary):not(.button--primary-dark):not(.button--plain) {
      background-color: #FFF;
      color: $color-bob-blue;
      border-color: transparent;

      &:hover,
      &:focus {
        background-color: darken(#FFF, 5%);
      }
    }

    &:not(.button--primary) {
      color: #FFF;
    }

    &--secondary {
      &:hover,
      &:focus {
        background-color: lighten($color-bob-blue, 3%);
      }
    }
  }
}
