/* Newsletter
-------------------------------------------------- */

.newsletter {
  margin-bottom: $module-padding / 2;
  padding: $content-box-padding;
  border-radius: $default-border-radius;

  @include breakpoint(md) {
    margin-bottom: $module-padding;
  }

  &-wrapper {
    @include breakpoint(md) {
      display: flex;
      justify-content: space-between;
    }

    > * {
      @include breakpoint(md) {
        flex-basis: 50%;
      }
    }
  }

  &-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;

    @include breakpoint(md) {
      flex-direction: row;
    }

    &__content {
      flex-grow: 1;

      @include breakpoint(md) {
        margin: 0 1rem;
      }
    }

    &__icon {
      position: relative;
      width: 8.375rem;
      height: auto;
      min-width: 3.125rem;
      min-height: 3.125rem;
      color: #FFF;
      opacity: 0.5;
      right: -0.625rem;
      top: -0.625rem;

      @include breakpoint(md) {
        right: auto;
        top: auto;
        position: static;
        width: 3.75rem;
      }
    }
  }

  &-form {
    display: flex !important;
    flex-direction: column;

    &__container {
      order: 1;
      margin-bottom: 0;
      // label & email-inputfield
      .newsletter-form__row:first-child {
        @include breakpoint(xs) {
          padding-bottom: 1rem;
        }

        @include breakpoint(sm) {
          padding-bottom: $content-margin-vertical;
        }
        // label
        .newsletter-form__col--left {
          display: none;
        }
      }
      // add-on & submit-button
      .newsletter-form__row:last-child {
        @include breakpoint(sm) {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
        }

        .newsletter-form__button--submit {
          @include breakpoint(xs) {
            width: 100%;
            margin-bottom: $content-margin-vertical;
            min-width: 0;
          }

          @include breakpoint(sm) {
            min-width: 0;
          }
        }
      }
    }

    &__message {
      order: 2;
    }

    &__loader {
      order: 3;
    }

    // overwrite n2go defaults
    .input input[type="email"] {
      background-color: $color-bob-white;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      display: block;
      width: 100%;
      border: 1px solid $color-bob-white !important;
      border-radius: 0.25em !important;
      margin: 0;
      padding: 0.45rem 0.75rem !important;
      font-family: "Foundry", "Arial", sans-serif;
      font-weight: 500;
      font-size: 1em;
      color: $color-bob-black;
      vertical-align: middle;
      outline: 0;
    }

    /*
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    border: 1px solid #8b8b8a;
    border-radius: .25em;
    margin: 0;
    padding: .45rem .75rem;
    font-family: Foundry,Arial,sans-serif;
    font-weight: 500;
    font-size: 1em;
    color: #000;
    vertical-align: middle;
    outline: 0; */
  }

  &-legal p:last-child {
    margin-bottom: 0;

    @include breakpoint(md) {
      margin-left: 4.75rem;
    }
  }
}
