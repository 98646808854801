/* Carousel
-------------------------------------------------- */

.module .carousel {
  box-sizing: content-box;
  width: 100%;
  max-width: $grid-max-width;
  margin: -$default-box-shadow-blur auto;
  padding: $default-box-shadow-blur $grid-outer-margin;
}

.carousel {
  overflow: hidden;
  position: relative;

  &__clip--center &__wrapper {
    justify-content: center;
  }

  &__wrapper {
    white-space: nowrap; // fallback
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    user-select: none;
    list-style: none;
    margin: 0;
    padding: 0;

    > * {
      display: inline-block; // fallback
      vertical-align: top; // fallback
      position: relative; // fallback
      width: 100%; // fallback
      height: 100%;
      flex: 1 0 100%;
      white-space: normal;

      figure,
      source,
      picture,
      img {
        // pointer-events: none;
      }
    }

    &.is-animating {
      transition: all 0.4s ease;
    }
  }

  &.is-disabled {
    .carousel__wrapper {
      transform: none !important;
    }

    .carousel__nav {
      display: none !important;
    }
  }

  &__nav {
    margin: $content-margin-vertical 0 0;
    line-height: 0;
    text-align: center;
  }

  &__nav li {
    position: relative;
    display: inline-block;
    margin: 0 0.25rem;
    padding: 0.5rem;
    color: inherit;
    cursor: pointer;
    transition: all 0.3s ease-out;
    opacity: 0.5;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }

  &__nav li::before {
    content: "";
    background: $primary-color;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
  }

  &__nav-btn-row {
    display: flex;
    justify-content: space-between;

    button {
      width: 30px;
      height: 30px;
      font-weight: bold;
      color: white;
      background: $primary-color;

      /* opacity: 0.5; */

      &:hover {
        background: $color-bob-yellow;
      }
    }
  }
}
