/* Tiles List
-------------------------------------------------- */

.hero-content + .tiles-list {
  padding-top: 0;

  @include breakpoint(lg) {
    margin-top: -2rem;
  }
}

.tiles-list {
  .row {
    margin-bottom: 0;
  }

  &__item {

    /* background: #FFF;

    @include breakpoint(md) {
      background: transparent;
    }
     */
    &--content {

      /*
      display: flex;
      flex-wrap: nowrap;
      border-radius: 0;
      box-shadow: 0;
       */
      padding: $content-box-padding;
      background: $color-bob-white;
      display: block;
      border-radius: ($default-border-radius / 2);
      box-shadow: $default-box-shadow;
      height: 100%;

      .icon {
        width: 6em;
        height: 6em;
        color: $color-bob-blue;
        margin-bottom: $content-margin-vertical;
      }

      &--text {
        text-align: center;
        margin-left: 0;

        .button {
          min-width: auto;
          max-width: 100%;

          /* min-width: 100%; */
        }

        .copy {
          color: $color-bob-blue;

          /* margin-bottom: ($content-margin-vertical / 2); */
          display: block;
          height: auto;
          overflow: hidden;
          width: 100%;
          font-weight: 700;
          margin-bottom: $content-margin-vertical;
          background: transparent;
          text-align: center;
          font-size: rem(18px);

          /* @include breakpoint(md) {
            height: 60px;
          } */
        }
      }
    }
  }

  &__item + &__item {
    margin-top: $content-margin-vertical;

    @include breakpoint(md) {
      margin-top: 0;
    }
  }
}
