/* Search: all styles
-------------------------------------------------- */

.search {
  &-form {
    // padding-bottom: $content-margin-vertical;
  }

  &-result {
    &__list {
      padding: $content-margin-vertical 0;
      list-style-type: none;

      &-item + &-item {
        margin-top: $content-margin-vertical;
      }
    }

    &-entry {
      &__link {
        display: block;
        overflow: hidden;
        border-radius: $default-border-radius;
        box-shadow: $default-box-shadow;
        padding: $content-box-padding;
        text-decoration: none;
        transform: scale(1);

        @include breakpoint(md) {
          padding: $content-box-padding * 2;
        }

        &:hover,
        &:focus {
          transform: scale(1.02);
          transition: transform $transition-box;

          .headline {
            color: $text-link;
          }
        }
      }
    }
  }
}
