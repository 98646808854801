/* Contact Person
-------------------------------------------------- */

.contact-person {
  &__info {
    margin-bottom: $content-margin-vertical * 2;
  }

  &-teaser {
    background: #FFF;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: $default-border-radius;
    box-shadow: $default-box-shadow;
    padding: $content-box-padding;

    &__image {
      margin: #{-$content-box-padding} 0 $content-box-padding;

      @include breakpoint(sm) {
        margin: #{-$content-box-padding} 0 #{-$content-box-padding} #{-$content-box-padding};
      }
    }

    &__callback {
      background: #FFF;
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: $default-border-radius;
      box-shadow: $default-box-shadow;
      padding: 0 $content-box-padding;

      &--form {
        background: $color-bob-blue-90;

        .wrapper {
          width: 90%;
          margin: 0 auto;
          padding: $content-box-padding 0;

          .freeform-column {
            &.type-html {
              display: none;
            }
          }

          /* hide global form error */
          .ff-form-errors {
            display: none;
          }
        }
      }

      &--content {
        padding-bottom: $content-box-padding;
        padding-top: 0;

        * {
          width: 96%;
          margin: 0 auto;
        }

        .background-image {
          margin-bottom: 15px;
        }

        .text--richtext {
          width: 100%;
        }
      }
    }

    &__content {
      @include breakpoint(sm) {
        text-align: left;

        .headline,
        .button-group--vertical {
          display: block;
          text-align: left;
        }
      }

      &-copy {
        margin-bottom: $content-margin-vertical;
      }
    }
  }
}
