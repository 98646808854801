
/* partner list
-------------------------------------------------- */

.partnerlist {
  &-list {
    @include breakpoint(md) {
      justify-content: center;
    }

    &__item {
      text-align: center;
      margin-bottom: $content-margin-vertical;

      @include breakpoint(sm) {
        font-size: #{24px / $font-size}rem;
      }

      @include breakpoint(md) {
        padding-left: 3.5rem;
        text-align: left;
      }
    }
  }
}
