/* Image Gallery
-------------------------------------------------- */

.image-gallery {
  .carousel {
    padding-right: 0;
    padding-left: 0;

    &__clip {
      @media (max-width : $grid-max-width - 1) {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  figcaption {
    margin-bottom: -1em;
  }
}
