/* Hero Content
-------------------------------------------------- */

.hero-content {
  overflow: hidden;
  position: relative;
  padding: 0;
  color: #FFF;

  &--dark {
    color: #000;
  }

  &__content {
    height: 100%;
    padding: 2rem 0 50vw;

    @include breakpoint(xs) {
      padding: 1rem 0 48vw;
    }

    @include breakpoint(sm) {
      padding: 2rem 0;
    }

    @include breakpoint(md) {
      height: 560px;
    }

    &-logo {
      display: inline-block;
      height: 5rem;

      @include breakpoint(xs) {
        height: 3rem;
      }
    }

    h1.headline.headline--1 {
      @include breakpoint(xs) {
        font-size: 1.75rem;
      }
    }
  }

  &__background-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center bottom;
    background-size: contain;

    @include breakpoint(md) {
      background-size: cover;
      background-position: center top;
    }

    @media (min-width : 1440px /* Background-image size */) {
      background-size: contain;
    }
  }

  &.fullImage {
    .container--wide {
      padding: 0;

      @include breakpoint(md) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    color: #FFF;

    &--dark {
      color: #000;
    }

    .hero-content__background-image {
      @include breakpoint(md) {
        background-size: cover;
        background-position: center top;
      }

      /* @media (min-width : 1440px !* Background-image size *!) {
        background-size: contain;
      } */
    }

    .headline,
    .hero-content__content-logo {
      opacity: 0;
      transition: visibility 0.75s linear, opacity 0.75s linear;
    }
  }

  &.module--is-visible {
    .headline,
    .hero-content__content-logo {
      visibility: visible;
      opacity: 1;
    }
  }
}
